import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";

const RadioOption = ({
  name,
  disabled,
  onChange,
  value,
  className,
  defaultChecked,
  checked,
  onInput,
  children,
}) => {
  const theme = useContext(ThemeContext).theme;
  return (
    <label
      className={`text-sm my-4 grid grid-cols-[auto_1fr] gap-4 items-center`}
    >
      <input
        onChange={(e) => {
          if (typeof onChange === "function") onChange(e.target.value);
        }}
        onInput={onInput}
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        type="radio"
        name={name}
        value={value}
        className={`appearance-none rounded-full border-2 ${theme === "dark" ? "border-[#038ddc]" : "border-snow-blue-3"} disabled:border-[#cccccc] w-[16px] h-[16px] checked:border-[6px] ${className}`}
        data-testid={`radio-${name}_${value}`}
      />
      {children}
    </label>
  );
};

export default RadioOption;
