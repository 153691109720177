import React from "react";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

// Components
import HeaderBar from "../../../components/Header/HeaderBar";
import AppLinks from "../../LandingPage/components/AppLinks";
import ContentWrapper from "../../../components/ContentWrapper";
const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";
const DashboardPage = () => {
  const [searchParams] = useSearchParams();
  let showPurchaseSuccess = searchParams.get("show_purchase_success") === "1";

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar buttons={[]} title="SnowScape Dashboard" />
        <ContentWrapper>
          {showPurchaseSuccess ? (
            <div
              className={`purchaseSuccess ${sectionStyle} flex flex-col items-center`}
              data-testid="purchase-success"
            >
              <svg
                width="100"
                height="100"
                version="1.1"
                viewBox="0 0 26.458 26.458"
                className="text-snow-green w-[100px] h-[100px] my-6"
              >
                <g transform="translate(0 -270.54)">
                  <path
                    d="m13.229 270.54a13.229 13.229 0 0 0-13.229 13.229 13.229 13.229 0 0 0 13.229 13.229 13.229 13.229 0 0 0 13.229-13.229 13.229 13.229 0 0 0-13.229-13.229zm4.0281 8.843c0.31888 0.0106 0.63618 0.12826 0.89528 0.35673 0.59223 0.52222 0.64894 1.4192 0.12672 2.0114l-5.1243 5.8113-0.09678 0.10979c-0.35902 0.40711-0.89529 0.56115-1.3878 0.44786-0.08957-0.0206-0.17763-0.0502-0.26299-0.0885-0.12804-0.0575-0.24959-0.13513-0.36063-0.23304l-2.741-2.4172c-0.59223-0.52222-0.64893-1.4192-0.12672-2.0115l0.096776-0.10979c0.52222-0.59223 1.4192-0.64851 2.0115-0.12629l1.6721 1.4746 4.1817-4.7424c0.29375-0.33313 0.70619-0.49659 1.1162-0.48301z"
                    fill="currentColor"
                  />
                </g>
              </svg>
              <div className="flex flex-col">
                <h2 className="text-3xl font-semibold">
                  Thanks for Subscribing!
                </h2>
                <span className="text-description-gray">
                  Here are a few things you might want to do next:
                </span>
              </div>
              <div className="flex flex-row gap-2 justify-center mt-2">
                <DashboardButton to="/admin/property">
                  Create Properties
                </DashboardButton>
                <DashboardButton to="/admin/route">
                  Create Routes
                </DashboardButton>
                <DashboardButton to="/admin/employee">
                  Add Employees
                </DashboardButton>
              </div>
              <h3 className="text-2xl font-semibold mt-4">Install Our App</h3>
              <p className="text-description-gray">
                To service routes you will need to install our mobile
                application.
              </p>
              <div className="flex flex-row gap-2">
                <AppLinks />
              </div>
            </div>
          ) : null}
        </ContentWrapper>
      </div>
    </>
  );
};

function DashboardButton({ to, children }) {
  return (
    <Link
      to={to}
      className="border border-[#cccccc] rounded-md p-2 text-sm shadow-md font-semibold bg-full-white text-full-black"
    >
      {children}
    </Link>
  );
}
const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(DashboardPage);
export { DashboardButton };
