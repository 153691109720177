import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import HeaderBar from "../../../components/Header/HeaderBar";
import AddPaymentMethodModal from "../../../components/Modals/AddPaymentMethodModal";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";

import UnsubscribeConfirmModal from "../../../components/Modals/UnsubscribeConfirmModal";
import UnsubscribeReasonModal from "../../../components/Modals/UnsubscribeReasonModal";
import axiosContact from "../../../services/axios/contact";
import axiosStripe from "../../../services/axios/stripe";
import axiosUsers from "../../../services/axios/users";
import ContentWrapper from "../../../components/ContentWrapper";
import PaymentMethod from "../../../components/Billing/PaymentMethod";
import Usage from "./Usage";
import Subscriptions from "../../../components/Billing/Subscriptions";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import StandardButton from "../../../components/Buttons/StandardButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmWrapper } from "../../../components/Modals/ConfirmWrapper";
import axios from "axios";
import errors from "../../../services/errors";
import flash from "../../../services/flash";

const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";
const AccountPage = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [billingInformation, setBillingInformation] = useState(null);
  const [displayAddPaymentMethodModal, setDisplayAddPaymentMethodModal] =
    useState(false);
  const [displayUnsubscribeModal, setDisplayUnsubscribeModal] = useState(false);
  const [displayUnsubscribeReasonModal, setDisplayUnsubscribeReasonModal] =
    useState(false);
  const [unsubscirbeSubscriptionId, setUnsubscribeSubscriptionId] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [nextPaymentInfo, setNextPaymentInfo] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const paymentRef = useRef();

  const companyQuery = useQuery({
    queryKey: [`company/${props.currentUser?.currentCompanyId}`],
    enabled: props.currentUser?.currentCompanyId != null,
  });

  const fetchAccountData = useCallback(() => {
    setPageLoading(true);

    // Get user's account, billing, products and subscription information
    axiosUsers.getUserAccountInformationById(
      { uid: props.currentUser.uid },
      (data) => {
        // Set account information
        setBillingInformation(data.billingInformation);
        setAccountData(data);

        // Format tables for billing and subscriptions
        formatBillingTableData(
          data.billingInformation,
          data.subscriptionInformation,
        );

        setPageLoading(false);
      },
      (err) => {
        alert("Error in getting account info " + err);
      },
    );
  }, [props.currentUser.uid]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  // If redirecting here from a subscription change, set customerId then refresh
  const handleSuccessfulSubscribe = useCallback(
    (sessionId) => {
      if (!sessionId) return;

      // TECH DEBT: There is an issue here where the getAuth().currentUser is null when this page first loads.  Later
      // the props change a few times and in one of those subsequent refreshes, the currentUser object is available. This
      // also means that this network request gets sent to the backend 4-15 times.  This really needs fixed, but it works for
      // now.  We work around it by checking if the currentUser is available.  Long term we should figure out why it's
      // not available on first load and find a way to await it.
      if (props.currentUser.uid) {
        axiosUsers.setStripeCustomerIdFromSessionId(
          {
            uid: props.currentUser.uid,
            sessionId,
          },
          () => {
            fetchAccountData();
          },
          (err) => {
            alert("Error in setting user.stripe.customerId " + err);
          },
        );
      }
    },
    [fetchAccountData, props.currentUser.uid],
  );

  // Handle success of upgrade
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      const sessionId = query.get("session_id");
      handleSuccessfulSubscribe(sessionId);
    }
  }, [handleSuccessfulSubscribe]);

  // Format the Billing Information Table
  const formatBillingTableData = (billingData, subscriptionData) => {
    // If no billing information/subscription information (eg. not subscribed), don't format billing table
    if (!subscriptionData) return;

    // Format next payment date
    const currentPeriodEnd = subscriptionData[0]?.current_period_end * 1000;
    const nextPaymentDate = currentPeriodEnd
      ? new Date(currentPeriodEnd)?.toLocaleDateString()
      : "N/A";
    setNextPaymentInfo({
      date: nextPaymentDate,
      amount: billingData?.nextChargeAmount,
    });
  };

  const handleUnsubscribe = async (subscriptionId, message) => {
    const customer = await axiosStripe.getCustomerById({
      uid: props.currentUser.uid,
    });

    await axiosContact.sendContactUsEmail({
      email: customer.email,
      message: `Subscription: ${subscriptionId} - Reason for cancellation: ${message}`,
      name: `${props.currentUser.firstName} ${props.currentUser.lastName}`,
      type: "contact-us",
    });

    axiosStripe.removeSubscription(
      { subscriptionId, uid: props.currentUser.uid },
      () => {
        alert(
          "Successfully unsubscribed. Your subscription will end at the end of this billing period.",
        );

        // Refresh account data
        fetchAccountData();
      },
      (err) => {
        console.error("err: ", err);
        alert(
          "Sorry about that. It seems something went wrong on our end. Please try again.",
        );

        // Refresh account data
        fetchAccountData();
      },
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar title="Billing / Subscriptions" />
        <ContentWrapper loading={pageLoading}>
          {pageLoading ? null : (
            <>
              {accountData.isCustomer ? (
                <>
                  <section className={sectionStyle}>
                    <h2 className="text-2xl text-full-black font-bold">
                      Your Subscriptions
                    </h2>
                    <p className="text-description-gray max-w-[600px] mb-2">
                      If you need to pause / park your subscription until the
                      next season, just switch to our free plan and we will keep
                      all your information safe until you need it again.
                    </p>

                    {accountData == null ? null : (
                      <div className="flex flex-row gap-3 my-4 align-middle">
                        <Usage
                          name="Companies"
                          using={accountData.usage.activeCompanies}
                          total={accountData.authorizations.authorizedCompanies}
                        />
                        <Usage
                          name="Users"
                          using={accountData.usage.activeUsers}
                          total={accountData.authorizations.authorizedUsers}
                        />
                      </div>
                    )}
                    {/* Subscription Information */}
                    <Subscriptions
                      showChangePlanFormDefault={
                        searchParams.get("show-plans") === "1"
                      }
                      subscriptionInformation={
                        accountData.subscriptionInformation
                      }
                      productInformation={accountData.productInformation}
                      hasPaymentMethod={accountData.billingInformation != null}
                      beforeSubscriptionChange={async (requestedPlan) => {
                        const allowedUsers =
                          requestedPlan.plan.users + requestedPlan.extra_users;
                        const allowedCompanies =
                          requestedPlan.plan.companies +
                          requestedPlan.extra_companies;
                        if (
                          accountData.usage.activeCompanies >
                            allowedCompanies ||
                          accountData.usage.activeUsers > allowedUsers
                        ) {
                          return await confirmWrapper("Are you sure?", {
                            description:
                              "You have more companies / users than this plan allows.  If you switch to this plan you will not be able to use our mobile app until you deactivate some users / companies.",
                            okText: "Switch Anyway",
                          });
                        }
                        return true;
                      }}
                      onSubscriptionChange={() => {
                        fetchAccountData();
                        queryClient.invalidateQueries({
                          queryKey: [`user/${props.currentUser.uid}/account`],
                        });
                      }}
                      currentUser={props.currentUser}
                      onPaymentMethodNeeded={() => {
                        // Add an animation to draw attention to the section:
                        if (paymentRef.current) {
                          paymentRef.current.style.animation = null;
                          paymentRef.current.offsetHeight;
                          paymentRef.current.style.animation =
                            "flashElement 500ms 3";
                        }
                      }}
                    />
                  </section>

                  <section className={sectionStyle} ref={paymentRef}>
                    <h2 className="text-2xl text-full-black font-bold">
                      Payment Methods
                    </h2>
                    <p className="text-description-gray max-w-[600px] mb-2">
                      We have the following payment methods on record for your
                      account. To stop billing, switch to our free plan or
                      cancel your subscription.
                      {nextPaymentInfo ? (
                        <>
                          <br />
                          Your next payment will be charged on{" "}
                          {nextPaymentInfo.date}{" "}
                          {nextPaymentInfo.amount
                            ? `for ${nextPaymentInfo.amount}`
                            : null}
                          .
                        </>
                      ) : null}
                    </p>
                    <PaymentMethod
                      paymentMethods={accountData.paymentMethods}
                      defaultPaymentMethodId={billingInformation?.id}
                      currentUser={props.currentUser}
                      onChange={() => fetchAccountData()}
                    />
                  </section>
                </>
              ) : (
                <>
                  {/* TODO: put an error boundary around this */}
                  {companyQuery.isLoading ||
                  companyQuery.data.owner.uid ===
                    props.currentUser.uid ? null : (
                    <section className={sectionStyle}>
                      <h2 className="text-2xl text-full-black font-bold pb-2">
                        Manage Subscription for{" "}
                        {
                          props.currentUser.companies[
                            props.currentUser.currentCompanyId
                          ].companyName
                        }
                      </h2>
                      {companyQuery.isLoading ? null : (
                        <p className="text-description-gray">
                          This company is owned by{" "}
                          {companyQuery.data.owner.firstName}{" "}
                          {companyQuery.data.owner.lastName} (
                          {companyQuery.data.owner.phoneNumber}). Only the owner
                          can manage the payment and plan.
                        </p>
                      )}
                    </section>
                  )}
                  <section className={sectionStyle}>
                    <h2 className="text-2xl text-full-black font-bold pb-2">
                      Subscribe to SnowScape!
                    </h2>
                    <p className="text-description-gray">
                      Subscribe to SnowScape and enjoy the full power of our
                      platform. Once you pick your plan you will be able to
                      create your own companies and add your employees.
                    </p>
                    <StandardButton
                      className="max-w-[300px] mt-2"
                      onClick={() => navigate("/sign-up")}
                    >
                      Start Your Own Company
                    </StandardButton>
                  </section>
                </>
              )}
            </>
          )}
        </ContentWrapper>
      </div>
      {/* Add Payment Method Modal */}
      <AddPaymentMethodModal
        adjustForSidebar={true}
        open={displayAddPaymentMethodModal}
        onClose={() => {
          setDisplayAddPaymentMethodModal(false);
          fetchAccountData();
        }}
        title="Add Payment Method"
      />

      <UnsubscribeConfirmModal
        adjustForSidebar
        open={displayUnsubscribeModal}
        onCancel={() => {
          setDisplayUnsubscribeModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onEndSubscription={() => {
          setDisplayUnsubscribeModal(false);
          setDisplayUnsubscribeReasonModal(true);
        }}
      />
      <UnsubscribeReasonModal
        adjustForSidebar
        closeModal={() => {
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onUnsubscribe={async (message) => {
          await handleUnsubscribe(unsubscirbeSubscriptionId, message);
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        open={displayUnsubscribeReasonModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AccountPage);
export { AccountPage };
