import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import StandardButton from "../../components/Buttons/StandardButton";
import HeaderBar from "../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../components/Inputs/LabeledTextboxInput";
import MultipleChoiceModal from "../../components/Modals/MultipleChoiceModal";

// Services
import RoundButton from "../../components/Buttons/RoundButton";
import axiosPushNotifications from "../../services/axios/pushNotifications";
import { auth } from "@snowscape/snow-lib";

const DeveloperBroadcastPushNotification = (props) => {
  const navigate = useNavigate();

  const [body, setBody] = useState("");
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [title, setTitle] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    // Check if current user is super admin, else return to /admin
    if (!auth.canSuperAdmin(props.currentUser)) {
      navigate("/admin");
    }
  }, []);

  const handleSendPushNotification = () => {
    setSubmitLoading(true);
    axiosPushNotifications.broadcastPushNotificationToAll(
      { body, title },
      (data) => {
        console.log("data: ", data);
        setSubmitLoading(false);
        navigate(-1);
      },
    );
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <HeaderBar title="Broadcast Push Notification" />

        <div className="flex flex-1 flex-col w-full p-10 overflow-y-scroll">
          <div className="flex flex-row w-full justify-end">
            <StandardButton
              color="blue"
              label="Back"
              onClick={() => navigate(-1)}
            />
          </div>

          <div className="text-xl text-snow-primary mt-4 mb-2">
            Please enter the title and message body you would like to broadcast
            through push notifications.
          </div>
          <div className="text-xl text-snow-red font-bold mb-6">
            WARNING: This message will be sent to every mobile device using
            SnowScape. Please be sure to check for spelling errors and ensure
            the accuracy of your input before pressing 'Send'.
          </div>
          <LabeledTextboxInput
            disabled={false}
            label="Title"
            value={title}
            setValue={setTitle}
          />
          <LabeledTextboxInput
            disabled={false}
            multiline={true}
            label="Body"
            value={body}
            setValue={setBody}
          />
          <RoundButton
            color="green"
            disabled={!title || !body}
            fullWidth
            label="Send"
            loading={submitLoading}
            onClick={() => setDisplayConfirmationModal(true)}
          />
        </div>
      </div>
      {/* Delete Payment Method Modal */}
      <MultipleChoiceModal
        adjustForSidebar={true}
        open={displayConfirmationModal}
        options={[
          {
            color: "green",
            label: "Send",
            onClick: handleSendPushNotification,
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayConfirmationModal(false),
          },
        ]}
        subtitle="Are you sure you want to send the push notification to every user of our app?"
        title="WARNING"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(DeveloperBroadcastPushNotification);
