import { createSlice } from "@reduxjs/toolkit";

const metadataSlice = createSlice({
  name: "metadata",
  initialState: {
    flashMessage: "",
    flashType: "success",
    features: {
      updatedAt: null,
    },
  },
  reducers: {
    flashMessage(state, action) {
      state.flashMessage = action.payload.message;
      state.flashType = action.payload.type ?? "success";
    },
    flashMessageRemove(state) {
      state.flashMessage = "";
      state.flashType = "success";
    },
    setFeaturesForCompany(state, action) {
      if (state.features == null) {
        state.features = { updatedAt: null };
      }
      if (
        state.features[action.payload.companyId] == null ||
        JSON.stringify(state.features[action.payload.companyId]) !==
          JSON.stringify(action.payload.features)
      ) {
        state.features[action.payload.companyId] = action.payload.features;
      }
      state.features.updatedAt = new Date().toISOString();
    },
  },
});

export const { flashMessage, flashMessageRemove, setFeaturesForCompany } =
  metadataSlice.actions;

export default metadataSlice.reducer;
