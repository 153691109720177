import React from "react";
const CheckBox = ({
  name,
  label,
  disabled,
  onChange,
  required,
  className,
  onInput,
  testId,
  defaultChecked,
  autoFocus,
  checked,
  description,
}) => {
  const input = (
    <input
      type="checkbox"
      className={`css-checkbox ${className}`}
      name={name}
      disabled={disabled}
      onChange={(e) => {
        if (typeof onChange === "function") {
          onChange(e.target.value);
        }
      }}
      checked={checked}
      required={required ?? false}
      onInput={onInput}
      data-testid={testId ?? name}
      defaultChecked={defaultChecked}
      autoFocus={autoFocus === true}
    />
  );
  if (label) {
    return (
      <label className={`css-checkbox`}>
        {input}
        <div>
          {label}
          {description ? (
            <>
              :<span className="ml-2 text-description-gray">{description}</span>
            </>
          ) : null}
        </div>
      </label>
    );
  } else {
    return input;
  }
};

export default CheckBox;
