import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { updateUser } from "../../store/reducers/currentUserSlice";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axiosUsers from "../../services/axios/users";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import { auth } from "@snowscape/snow-lib";
/**
 * Until the app supports switching companies gracefully across pages, we will require whitelisting here
 * to allow a page to not be redirected to the home when the user switches companies.
 */
const routeToNavigateToAfterSwitchingCompany = (url) => {
  // return null;
  // eslint-disable-next-line no-unreachable
  let newRoute = "/admin/route";
  const pathToRoute = [
    {
      regex: /admin\/property\/history.*/,
      to: "/admin/property/history",
    },
    {
      regex: /admin\/property.*/,
      to: "/admin/property/",
    },
    {
      regex: /admin\/timeclock.*/,
      to: "/admin/timeclock",
    },
    {
      regex: /admin\/employee\/manage-admins.*/,
      to: "/admin/employee/manage-admins",
    },
    {
      regex: /admin\/employee.*/,
      to: "/admin/employee",
    },
    {
      regex: /admin\/report.*/,
      to: "/admin/report",
    },
    {
      regex: /admin\/material.*/,
      to: "/admin/material",
    },
    {
      regex: /admin\/company\/services.*/,
      to: "/admin/company/services",
    },
    {
      regex: /admin\/active-routes.*/,
      to: "/admin/active-routes",
    },
    {
      regex: /admin\/settings.*/,
      to: null,
    },
  ];
  for (const check of pathToRoute) {
    if (check.regex.test(url)) {
      newRoute = check.to;
      break;
    }
  }
  return newRoute == url ? null : newRoute;
};

const SwitchCompanyDropdown = ({ currentUser }) => {
  const [user] = useAuthState(firebaseAuth.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const handleChangeCompany = async (companyId) => {
    dispatch(
      updateUser({
        ...currentUser,
        currentCompanyId: companyId,
        isPaid: true,
        isPaidUpdatedAt: Date.now(),
      }),
    );

    const targetRoute = routeToNavigateToAfterSwitchingCompany(pathname);
    if (targetRoute) {
      navigate(targetRoute);
    }
    return;
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await axiosUsers.getCompaniesAsync(currentUser.uid);
      setCompanyOptions(
        data.userCompanies
          .filter((c) => auth.canAdminCompany(currentUser, c.companyId))
          .map((c) => ({
            label: c.companyName + (c.isPaid ? "" : " (inactive)"),
            value: c.companyId,
          })),
      );
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    // Need to wait for a token before we send any requests
    if (currentUser.uid && user) {
      fetchData();
    }
  }, [currentUser, fetchData, user]);

  return (
    <>
      <Select
        isLoading={loading}
        onChange={(option) => handleChangeCompany(option.value)}
        options={companyOptions}
        placeholder="Select Company"
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: "#829399",
          }),
        }}
        value={companyOptions.find(
          (o) => o.value == currentUser.currentCompanyId,
        )}
      />
    </>
  );
};
export default connect((state) => ({ currentUser: state.currentUser }))(
  SwitchCompanyDropdown,
);
export { SwitchCompanyDropdown };
