import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import SplitForm from "../../components/SplitForm";
import SquareButton from "../../components/Buttons/SquareButton";
import { goToStripe } from "./SignUpPage";
import { phoneNumberFormatted } from "../LandingPage/ContactUsPage";
const StripeCancelPage = () => {
  const [searchParams] = useSearchParams();
  let email = searchParams.get("email");
  let plan = searchParams.get("plan");

  return (
    <>
      <SplitForm>
        <div className="grid h-full" data-testid="stripe-cancel-page">
          <div className="text-full-white place-self-center px-2">
            <h1 className="text-2xl lg:text-3xl font-bold">Payment Canceled</h1>
            <p className="py-2">Do you want to try again?</p>
            <SquareButton
              onClick={async () => await goToStripe(plan, 0, 0, email)}
              testId="go-to-payment-button"
            >
              Go to Payment
            </SquareButton>
            <p className="pt-2">
              Having trouble? Give us a call at {phoneNumberFormatted}.
            </p>
            <p>
              You can also try out our{" "}
              <Link to="/sign-up?plan=hansolo-free-sub" className="underline">
                free plan
              </Link>
              .
            </p>
          </div>
        </div>
      </SplitForm>
    </>
  );
};
export default StripeCancelPage;
