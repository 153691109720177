import React from "react";
import AuthLabeledTextInput from "./AuthLabeledTextInput";

function PhoneNumberInput({
  setValue,
  name = "phone",
  label = "Mobile Phone Number",
  placeholder = "712 555 0100",
  testId,
  className,
  value,
  required,
  defaultValue,
  autoFocus,
  showValidation,
  disabled,
}) {
  return (
    <>
      <AuthLabeledTextInput
        label={label}
        name={name}
        maxLength={10}
        placeholder={placeholder}
        inputMode="tel"
        pattern="[0-9]{10}"
        required={required}
        defaultValue={defaultValue}
        value={value}
        setValue={setValue}
        autoFocus={autoFocus}
        disabled={disabled}
        className={className}
        testId={testId}
        showValidation={showValidation}
        onInput={(e) => {
          let target = e.nativeEvent.target;
          if (target.validity.patternMismatch) {
            target.setCustomValidity(
              "Please enter a US or Canadian phone number with area code. i.e. 712 555 0100",
            );
          } else {
            target.setCustomValidity("");
          }
        }}
      />
    </>
  );
}
function normalizePhoneNumberValue(value) {
  if (typeof value !== "string") {
    return null;
  }
  value = value.replace(/[^\d+]/g, "");
  if (value.length < 11) {
    value = "+1" + value;
  } else if (value.length < 12 && value.charAt(0) === "1") {
    value = "+" + value;
  }
  return value;
}
export default PhoneNumberInput;
export { normalizePhoneNumberValue };
