import React, { useContext, useState } from "react";
import RadioOption from "../../components/Inputs/RadioOption";
import { AddOnPricing, PricingData } from "../../components/Pricing";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import {
  CoreInputStyles,
  DARK_THEME,
  LIGHT_THEME,
} from "../../components/Inputs/BasicInput";
import { ThemeContext } from "../../contexts/ThemeContext";

const ChoosePlanInput = ({
  name,
  onPlanChange,
  value = "team-sub",
  requiredUsers = 0,
  requiredCompanies = 0,
  defaultExtraUsers = 0,
  defaultExtraCompanies = 0,
  highlightPlan = null,
  defaultShowCustomizePlan = true,
}) => {
  const [extraUsers, setExtraUsers] = useState(defaultExtraUsers);
  const [extraCompanies, setExtraCompanies] = useState(defaultExtraCompanies);
  const [showCustomizePlan, setShowCustomizePlan] = useState(
    defaultShowCustomizePlan,
  );
  const [selectedPlan, setSelectedPlan] = useState(
    PricingData.find((p) => p.id === value),
  );
  return (
    <div data-testid="choose-plan-input">
      {PricingData.map((plan) => (
        <RadioOption
          key={plan.id}
          name={name ?? "plan"}
          value={plan.id}
          // defaultChecked={value ? plan.id === value : plan.default ?? false}
          checked={plan.id === selectedPlan.id}
          onChange={(value) => {
            console.log("value changed", value);
            setSelectedPlan(plan);
            if (typeof onPlanChange === "function") {
              onPlanChange(value);
            }
          }}
          disabled={
            Number(plan.companies) + Number(extraCompanies) <
              requiredCompanies ||
            Number(plan.users) + Number(extraUsers) < requiredUsers
          }
        >
          <div
            className={`inline-grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 lg:gap-2 w-[350px] ${highlightPlan === plan.id ? "font-bold" : ""}`}
          >
            <span className="flex gap-2">
              <span className="">{plan.name}</span>
              <span>${plan.price} / month</span>
            </span>
            <span className="">
              {plan.companies} {plan.companies > 1 ? "companies" : "company"},{" "}
              {plan.users} {plan.users > 1 ? "users" : "user"}
            </span>
          </div>
        </RadioOption>
      ))}
      {showCustomizePlan ? (
        <>
          <div data-testid="add-ons" className="flex gap-2 max-w-[400px]">
            <AuthLabeledTextInput
              label={`Extra Users $${AddOnPricing.extra_users.price}`}
              name="extra_users"
              value={extraUsers}
              setValue={setExtraUsers}
              numbersOnly={true}
            />
            <AuthLabeledTextInput
              label={`Extra Companies $${AddOnPricing.extra_companies.price}`}
              name="extra_companies"
              value={extraCompanies}
              setValue={setExtraCompanies}
              numbersOnly={true}
            />
          </div>
          <p className=" flex flex-col">
            <span data-testid="total-users" className="flex justify-between">
              <span>Total Users:</span>
              <span>
                {selectedPlan.users} + {extraUsers} ={" "}
                {Number(selectedPlan.users) + Number(extraUsers)}
              </span>
            </span>
            <span
              data-testid="total-companies"
              className="flex justify-between"
            >
              <span>Total Companies:</span>
              <span>
                {selectedPlan.companies} + {extraCompanies} ={" "}
                {Number(selectedPlan.companies) + Number(extraCompanies)}
              </span>
            </span>
          </p>
          <span
            data-testid="total-cost"
            className="flex justify-between font-bold"
          >
            <span>Total Monthly Cost:</span>
            <span>
              $
              {Math.round(
                (Number(selectedPlan.price) +
                  Number(extraUsers) * AddOnPricing.extra_users.price +
                  Number(extraCompanies) * AddOnPricing.extra_companies.price) *
                  100,
              ) / 100}
            </span>
          </span>
        </>
      ) : (
        <>
          <button
            className="text-sm"
            onClick={() => setShowCustomizePlan(true)}
          >
            <span className="underline">Customize Your Plan</span> (extra users
            / companies)
          </button>
        </>
      )}
    </div>
  );
};

function suggestAPlan(requiredUsers, requiredCompanies) {
  return PricingData.find(
    (p) => p.companies >= requiredCompanies && p.users >= requiredUsers,
  );
}

function buildPlanFromForm(submitEvent) {
  let form = submitEvent.target;
  let plan = PricingData.find((p) => p.id === form.plan.value);
  let extra_users = Number(form.extra_users?.value ?? 0);
  let extra_companies = Number(form.extra_companies?.value ?? 0);
  return {
    plan,
    extra_users,
    extra_companies,
  };
}

export default ChoosePlanInput;
export { suggestAPlan, buildPlanFromForm };
