import React from "react";
import { createBrowserRouter } from "react-router-dom";

// Pages
import AdminAccountPage from "../pages/Admin/Account/AccountPage";
import AdminCreateCompanyPage from "../pages/Admin/Company/CreateCompanyPage";
import AdminUpdateCompanyPage from "../pages/Admin/Company/UpdateCompanyPage";
import AdminUpdateCompanyServicesPage from "../pages/Admin/Company/UpdateCompanyServicesPage";
import AdminEmployeeDetailsPage from "../pages/Admin/Employees/EmployeeDetailsPage";
import AdminEmployeePage from "../pages/Admin/Employees/EmployeesPage";
import AdminManageAdminsPage from "../pages/Admin/Employees/ManageAdminsPage";
import AdminJobHistoryDetailsPage from "../pages/Admin/JobHistory/JobHistoryDetailsPage";
import AdminJobHistoryPage from "../pages/Admin/JobHistory/JobHistoryPage";
import AdminBulkImportJobs from "../pages/Admin/Jobs/BulkImportJobs";
import AdminCreateJobPage from "../pages/Admin/Jobs/CreateJobPage";
import AdminJobsPage from "../pages/Admin/Jobs/JobsPage";
import AdminUpdateJobPage from "../pages/Admin/Jobs/UpdateJobPage";
import AdminMaterialsPage from "../pages/Admin/Materials/MaterialsPage";
import AdminReportsPage from "../pages/Admin/Reports/ReportsPage";
import AdminTimeClockPageWrapper from "../pages/Admin/TimeClock/TimeClockPageWrapper";
import TimeRecordPage from "../pages/Admin/TimeClock/TimeRecordPage";
import AboutPage from "../pages/LandingPage/AboutPage";
import AdminLoginPage from "../pages/LandingPage/AdminLoginPage";
import HomePage from "../pages/LandingPage/HomePage";
import SnowScapeAdmin from "../pages/Developer/SnowScapeAdmin";

// import AdminPurchaseAddOnsPage from '../pages/Admin/Subscription/AdminPurchaseAddOnsPage';
import AdminWrapper from "../pages/Admin/AdminWrapper";
import ActiveRouteDetailsPage from "../pages/Admin/Routes/ActiveRouteDetails";
import ActiveRoutesPage from "../pages/Admin/Routes/ActiveRoutes";
import AdminCreateRoutePage from "../pages/Admin/Routes/CreateRoutePage";
import AdminRoutesPage from "../pages/Admin/Routes/RoutesPage";
import AdminUpdateRoutePage from "../pages/Admin/Routes/UpdateRoutePage";
import TimeClockEmployeePage from "../pages/Admin/TimeClock/TimeClockEmployeePage";
import CreateFirstCompany from "../pages/Auth/CreateFirstCompany";
import GetStartedPage from "../pages/Auth/GetStartedPage";
import DeveloperBroadcastPushNotification from "../pages/Developer/BroadcastPushNotification";
import ErrorPage from "../pages/ErrorPage";
import ContactUsPage from "../pages/LandingPage/ContactUsPage";
import DocsPage from "../pages/LandingPage/DocsPage";
import FAQPage from "../pages/LandingPage/FAQPage";
import FeaturesPage from "../pages/LandingPage/FeaturesPage";
// import HomePage from "../pages/LandingPage/HomePage";
import LandingPage from "../pages/LandingPage/LandingPage";
import PricingPage from "../pages/LandingPage/PricingPage";
import QuotePage from "../pages/LandingPage/QuotePage";
import CompaniesPage from "../pages/Admin/Company/CompaniesPage";

// New Landing Page

// Routing
import PrivateRoutes from "./PrivateRoutes";
import ProfileSettingsPage from "../pages/Settings/ProfileSettingsPage";
import AccountPage from "../pages/Admin/Account/AccountPage";
import DeactivateCompanyPage from "../pages/LandingPage/Docs/DeactivateCompanyPage";
import SignUpLayout from "../pages/SignUp/SignUpLayout";
import SignUpPage from "../pages/SignUp/SignUpPage";
// import PaymentPage from "../pages/SignUp/PaymentPage";
import DashboardPage from "../pages/Admin/Dashboard/DashboardPage";
import StripeSuccessPage from "../pages/SignUp/StripeSuccessPage";
import StripeCancelPage from "../pages/SignUp/StripeCancelPage";
import NewEmployeeInstructionsPage from "../pages/Admin/Employees/NewEmployeeInstructionsPage";
import InstallInstructions from "../pages/LandingPage/InstallInstructions";
import SubscriptionPaywallV2 from "../components/Stripe/SubscriptionPaywallV2";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/contact",
        element: <ContactUsPage />,
      },
      {
        path: "/pricing",
        element: <PricingPage />,
      },
      {
        path: "/pricing/quote",
        element: <QuotePage />,
      },
      {
        path: "/features",
        element: <FeaturesPage />,
      },
      {
        path: "/faq",
        element: <FAQPage />,
      },
      {
        path: "/docs",
        element: <DocsPage />,
      },
      {
        path: "/docs/guides/deactivate-company",
        element: <DeactivateCompanyPage />,
      },
      {
        path: "/admin-login",
        element: <AdminLoginPage />,
      },
      {
        path: "/get-started",
        element: <GetStartedPage />,
      },
      {
        path: "/create-first-company",
        element: <CreateFirstCompany />,
      },
      {
        path: "/install",
        element: <InstallInstructions />,
      },
    ],
  },
  {
    path: "/sign-up",
    element: <SignUpLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/sign-up",
        element: <SignUpPage />,
      },
      {
        path: "/sign-up/payment-success",
        element: <StripeSuccessPage />,
      },
      {
        path: "/sign-up/payment-cancel",
        element: <StripeCancelPage />,
      },
      // {
      //   path: "/sign-up/payment",
      //   element: <PaymentPage />,
      // },
    ],
  },
  {
    path: "/admin",
    element: (
      <PrivateRoutes>
        <AdminWrapper />
      </PrivateRoutes>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/admin",
        element: <AdminRoutesPage />,
      },
      {
        path: "/admin/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/admin/active-routes",
        element: (
          <SubscriptionPaywallV2>
            <ActiveRoutesPage />
          </SubscriptionPaywallV2>
        ),
      },
      {
        path: "/admin/active-routes/:routeId",
        element: <ActiveRouteDetailsPage />,
      },
      {
        path: "/admin/account",
        element: <AdminAccountPage />,
      },
      {
        path: "/admin/user/company/create",
        element: <AdminCreateCompanyPage />,
      },
      {
        path: "/admin/user/company/:companyId",
        element: <AdminUpdateCompanyPage />,
      },
      {
        path: "/admin/company/services",
        element: (
          <SubscriptionPaywallV2>
            <AdminUpdateCompanyServicesPage />
          </SubscriptionPaywallV2>
        ),
      },
      {
        path: "/admin/employee",
        element: <AdminEmployeePage />,
      },
      {
        path: "/admin/employee/:id",
        element: <AdminEmployeeDetailsPage />,
      },
      {
        path: "/admin/employee/manage-admins",
        element: <AdminManageAdminsPage />,
      },
      {
        path: "/admin/employee/:id/instructions",
        element: <NewEmployeeInstructionsPage />,
      },
      {
        path: "/admin/material/",
        element: (
          <SubscriptionPaywallV2>
            <AdminMaterialsPage />
          </SubscriptionPaywallV2>
        ),
      },
      {
        path: "/admin/property",
        element: (
          <SubscriptionPaywallV2>
            <AdminJobsPage />
          </SubscriptionPaywallV2>
        ),
      },
      {
        path: "/admin/timeclock",
        element: (
          <SubscriptionPaywallV2>
            <AdminTimeClockPageWrapper />
          </SubscriptionPaywallV2>
        ),
      },
      {
        path: "/admin/timeclock/:record_id",
        element: <TimeRecordPage />,
      },
      {
        path: "/admin/timeclock/employee/:user_id",
        element: <TimeClockEmployeePage />,
      },
      {
        path: "/admin/excel_import",
        element: <AdminBulkImportJobs />,
      },
      // {
      //     path: '/admin/purchase/addons',
      //     element: <AdminPurchaseAddOnsPage />,
      // },
      {
        path: "/admin/property/create",
        element: <AdminCreateJobPage />,
      },
      {
        path: "/admin/property/:jobId",
        element: <AdminUpdateJobPage />,
      },
      {
        path: "/admin/property/history",
        element: <AdminJobHistoryPage />,
      },
      {
        path: "/admin/property/history/:jobHistoryId",
        element: <AdminJobHistoryDetailsPage />,
      },
      {
        path: "/admin/report/",
        element: <AdminReportsPage />,
      },
      {
        path: "/admin/route",
        element: (
          <SubscriptionPaywallV2>
            <AdminRoutesPage />
          </SubscriptionPaywallV2>
        ),
      },
      {
        path: "/admin/route/create/:season",
        element: <AdminCreateRoutePage />,
      },
      {
        path: "/admin/route/:routeId",
        element: <AdminUpdateRoutePage />,
      },
      {
        path: "/admin/developer/broadcast-push-notification",
        element: <DeveloperBroadcastPushNotification />,
      },
      {
        path: "/admin/snowscape",
        element: <SnowScapeAdmin />,
      },
      {
        path: "/admin/settings/profile",
        element: <ProfileSettingsPage />,
      },
      {
        path: "/admin/settings/billing",
        element: <AccountPage />,
      },
      {
        path: "/admin/settings/company",
        element: <CompaniesPage />,
      },
    ],
  },
]);

export default router;
