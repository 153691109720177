// This component is designed to block any unpaid use of our services.

import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import UnlockWhiteIcon from "../../resources/unlock-white.svg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ButtonLink from "../Buttons/ButtonLink";
import SubtleButton from "../Buttons/SubtleButton";
import { useNavigate } from "react-router-dom";
import ContentWrapper from "../ContentWrapper";
import ChoosePlanInput, {
  buildPlanFromForm,
  suggestAPlan,
} from "../../pages/SignUp/ChoosePlanInput";
import axios from "axios";
import flash from "../../services/flash";
import { PricingData } from "../Pricing";
import Usage from "../../pages/Admin/Account/Usage";
import { useChangePlanMutation } from "../Billing/Subscriptions";
import CardForm from "../Billing/CardForm";
import { useFeatureFlag, User } from "configcat-react";
const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";

const SubscriptionPaywallV2 = ({ currentUser, currentCompanyId, children }) => {
  const navigate = useNavigate();
  const [submitRunning, setSubmitRunning] = useState(false);
  const queryClient = useQueryClient();
  let { value: enablePaywall } = useFeatureFlag(
    "paywallweb",
    false,
    new User(currentUser._id, null, null, {
      companyId: currentCompanyId,
    }),
  );
  const statusQuery = useQuery({
    queryKey: [`company/${currentCompanyId}/in-good-standing`],
    staleTime: 60 * 1000,
  });

  const changePlanMutation = useChangePlanMutation(currentUser);

  const onSubmit = useCallback(
    async (e) => {
      setSubmitRunning(true);
      try {
        e.preventDefault();
        await changePlanMutation
          .mutateAsync(buildPlanFromForm(e))
          .catch(() => {});
        console.log("submitted plan", e.target.plan.value);
      } finally {
        setSubmitRunning(false);
      }
    },
    [changePlanMutation],
  );

  if (!enablePaywall) {
    return children;
  }

  if (statusQuery.isLoading) {
    return null;
  } else {
    if (statusQuery.data.result) {
      return children;
    } else {
      const amOwner = statusQuery.data.amOwner;
      const ownerInfo = `${statusQuery.data.owner.firstName} ${statusQuery.data.owner.lastName} ${statusQuery.data.owner.phoneNumber}`;
      if (statusQuery.data.code === "no-license") {
        return (
          <ContentWrapper>
            <section className={`${sectionStyle}`}>
              <h2 className="text-xl font-bold border-b border-[#cccccc] mb-4">
                Company Is Inactive
              </h2>
              <p className="text-description-gray">
                This company is in-active. If you want to record new data please
                re-active this company.
              </p>
              <SubtleButton
                buttonRole="primary"
                onClick={() => {
                  navigate(`/admin/user/company/${currentCompanyId}`);
                }}
                className="my-2"
              >
                Company Settings
              </SubtleButton>
            </section>
          </ContentWrapper>
        );
      }
      if (statusQuery.data.code === "no-plan") {
        return (
          <ContentWrapper>
            <section className={`${sectionStyle}`}>
              <h2 className="text-xl font-bold border-b border-[#cccccc] mb-4">
                No Subscription
              </h2>
              <p className="text-description-gray">
                {amOwner ? (
                  <>
                    Please to to Settings &gt; Billing and sign up for a plan.
                  </>
                ) : (
                  <>
                    Please ask the company owner ({ownerInfo}) to subscribe to
                    one of our plans.
                  </>
                )}
              </p>
              {amOwner ? (
                <SubtleButton
                  buttonRole="primary"
                  onClick={() => {
                    navigate(`/admin/settings/billing`);
                  }}
                  className="my-2"
                >
                  Billing Settings
                </SubtleButton>
              ) : null}
            </section>
          </ContentWrapper>
        );
      }
      let tooManyUsers =
        statusQuery.data.usage.activeUsers >
        statusQuery.data.usage.authorizedUsers;
      let tooManyCompanies =
        statusQuery.data.usage.activeCompanies >
        statusQuery.data.usage.authorizedCompanies;
      let tooManyType =
        tooManyUsers && tooManyCompanies
          ? "users and companies"
          : tooManyUsers
            ? "users"
            : "companies";
      return (
        <>
          <ContentWrapper>
            <section className={`${sectionStyle}`}>
              <h2 className="text-xl font-bold border-b border-[#cccccc] mb-4">
                Upgrade Needed
              </h2>
              <p className="text-description-gray">
                We are glad to see how much you are using SnowScape! It looks
                like you are using more {tooManyType} than your current plan
                includes. Don't worry, you can easily upgrade your plan. Or, if
                you want to stay on your current plan, just select some{" "}
                {tooManyType} to deactivate.
              </p>
              {statusQuery.data.amOwner ? (
                <>
                  <div>
                    {statusQuery.data.usage.plans.length > 1 ? (
                      <>
                        <p className="my-2 text-description-gray">
                          You are subscribed to multiple plans. Please go to
                          Settings &gt; Billing to manage your subscriptions.
                        </p>
                        <SubtleButton
                          onClick={() => {
                            navigate("/admin/settings/billing");
                          }}
                          className="bg-snow-blue-3 text-full-white"
                        >
                          Subscription Settings
                        </SubtleButton>
                      </>
                    ) : (
                      <>
                        {statusQuery.data.hasPaymentMethod ? (
                          <>
                            <form onSubmit={onSubmit}>
                              {statusQuery.data.usage.plans.length > 0 ? (
                                <p className="text-description-gray mt-2 text-sm">
                                  Current Plan:{" "}
                                  {
                                    PricingData.find(
                                      (p) =>
                                        p.id ===
                                        statusQuery.data.usage.plans[0].plan,
                                    ).name
                                  }{" "}
                                  ({statusQuery.data.usage.authorizedUsers}{" "}
                                  users and{" "}
                                  {statusQuery.data.usage.authorizedCompanies}{" "}
                                  companies)
                                </p>
                              ) : null}
                              <ChoosePlanInput
                                name="plan"
                                value={pickPlan(statusQuery.data.usage)}
                                highlightPlan={
                                  statusQuery.data.usage
                                    ? pickPlan(statusQuery.data.usage)
                                    : null
                                }
                                requiredUsers={
                                  statusQuery.data.usage.activeUsers
                                }
                                requiredCompanies={
                                  statusQuery.data.usage.activeCompanies
                                }
                              />
                              <div className="my-3 flex gap-4">
                                <SubtleButton
                                  type="submit"
                                  className="bg-snow-blue-3 text-full-white"
                                  loading={submitRunning}
                                  testId="upgrade-subscription-button"
                                >
                                  <div className="flex gap-1 items-center">
                                    <svg
                                      width="26"
                                      height="26"
                                      viewBox="0 0 26 26"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.66669 11.9167V7.58333C8.66669 6.13889 9.53335 3.25 13 3.25C15.0695 3.25 16.2124 4.27951 16.7975 5.41667"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M20.5834 22.75H5.41669V11.9167H8.66669H17.3334H20.5834V22.75Z"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Upgrade Subscription
                                  </div>
                                </SubtleButton>
                              </div>
                            </form>
                            <div className="separator my-4">Or</div>
                            <p className="text-description-gray">
                              You can also bring your account into good standing
                              by removing extra {tooManyType}.
                            </p>
                            <div className="flex flex-row gap-3 mb-4 align-middle">
                              <Usage
                                name="Companies"
                                using={statusQuery.data.usage.activeCompanies}
                                total={
                                  statusQuery.data.usage.authorizedCompanies
                                }
                              />
                              <Usage
                                name="Users"
                                using={statusQuery.data.usage.activeUsers}
                                total={statusQuery.data.usage.authorizedUsers}
                              />
                            </div>
                            <div className="flex gap-2">
                              {tooManyCompanies ? (
                                <SubtleButton
                                  className="my-2"
                                  onClick={() => {
                                    navigate("/admin/settings/company");
                                  }}
                                >
                                  Remove Companies
                                </SubtleButton>
                              ) : null}
                              {tooManyUsers ? (
                                <SubtleButton
                                  className="my-2"
                                  onClick={() => {
                                    navigate("/admin/employee");
                                  }}
                                >
                                  Remove Employees
                                </SubtleButton>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`my-2 shadow-lg w-[337px] h-[212px] from-[#3274f0] to-[#9ec0f7] bg-gradient-to-br text-[#bac3c8] rounded-xl p-5 `}
                            >
                              <CardForm
                                currentUser={currentUser}
                                onSuccess={() => {
                                  queryClient.invalidateQueries({
                                    queryKey: [
                                      `company/${currentUser.currentCompanyId}/in-good-standing`,
                                    ],
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <p className="text-description-gray my-2 font-bold">
                  Please contact the company owner ({ownerInfo}) and ask them to
                  upgrade their subscription.
                </p>
              )}
              <p className="my-2 text-description-gray">
                If you need any help with your subscription, please feel free to
                reach out to us at{" "}
                <a className="underline" href="mailto:support@mysnowscape.com">
                  support@mysnowscape.com
                </a>
              </p>
            </section>
          </ContentWrapper>
        </>
      );
    }
  }
};

function pickPlan(usage) {
  let requiredCompanies = Math.max(
    usage.authorizedCompanies,
    usage.activeCompanies,
  );
  let requiredUsers = Math.max(usage.authorizedUsers, usage.activeUsers);
  return suggestAPlan(requiredUsers, requiredCompanies).id;
}

export default connect((state) => {
  return {
    currentCompanyId: state.currentUser.currentCompanyId,
    currentUser: state.currentUser,
  };
})(SubscriptionPaywallV2);
export { SubscriptionPaywallV2 };
