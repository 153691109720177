import React, { useContext } from "react";
import { CoreInputStyles, DARK_THEME, LIGHT_THEME } from "./BasicInput";
import { ThemeContext } from "../../contexts/ThemeContext";
const BasicTextArea = ({
  name,
  label,
  disabled,
  rows,
  maxLength,
  onChange,
  placeholder,
  value,
  className,
  testId,
}) => {
  const theme = useContext(ThemeContext).theme;
  const input = (
    <textarea
      name={name}
      className={`${CoreInputStyles} ${theme === "dark" ? DARK_THEME : LIGHT_THEME} ${className ?? ""}`}
      disabled={disabled}
      maxLength={maxLength}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      value={value}
      rows={rows}
      data-testid={testId ?? name}
    ></textarea>
  );
  if (label) {
    return (
      <label className={`text-sm text-full-white my-1 block`}>
        {label}
        {input}
      </label>
    );
  } else {
    return input;
  }
};

export default BasicTextArea;
