/**
 * Here we setup an axios instance for interacting with the backend.
 * It will setup the auth token automatically and take care of
 * refreshing it as needed.
 *
 * By setting up an instance here, we can still use axios for other
 * APIs without sending our auth token every time.
 */
import { getAuth, getIdToken } from "firebase/auth";
import axios from "axios";

const backend = axios.create({ withCredentials: true });
backend.interceptors.request.use(async function (config) {
  const token = await getIdToken(getAuth().currentUser);
  config.headers.Authorization = token;
  return config;
});
axios.defaults.withCredentials = true;
/**
 * @type {import('axios').AxiosInstance}
 */
export default backend;
