import axios from "axios";

// TESTING //
// axios.defaults.baseURL = 'http://localhost:5002/v2';

// V2 Production //
// axios.defaults.baseURL = 'https://138.197.20.250/v2/';

// V1 Production //
// axios.defaults.baseURL = 'http://157.245.116.105:5002/';
axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_API ?? "https://snowscapeserver.com/v2/";
const axiosConfig = { withCredentials: true };
export default axiosConfig;
