import axios from "./backendAxios";

const axiosActiveRoutes = {
  getActiveRouteById: async (routeId) => {
    const res = await axios.get(`activeRoute/route/${routeId}`);
    return res.data;
  },
};

export default axiosActiveRoutes;
