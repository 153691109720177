import React, { useState } from "react";
import BasicInput from "./BasicInput";

function AuthLabeledTextInput({
  setValue,
  numbersOnly,
  maxLength,
  minLength,
  onInput,
  name,
  label,
  testId,
  className,
  inputClassName,
  inputMode,
  placeholder,
  value,
  pattern,
  required,
  defaultValue,
  autoFocus,
  showValidation,
  disabled,
}) {
  const [validationMessage, setValidationMessage] = useState(null);
  const onChange = (value) => {
    if (typeof setValue === "function") {
      const re = /^[0-9\b]+$/;
      if (numbersOnly) {
        if (value === "" || re.test(value)) {
          setValue(value);
        }
      } else {
        setValue(value);
      }
    }
  };

  const internalOnInput = (e) => {
    if (typeof onInput === "function") {
      onInput(e);
    }
    let target = e.nativeEvent.target;
    setValidationMessage(
      target.validity.valid ? null : target.validationMessage,
    );
  };
  const testIdKey = testId ?? name ?? label;

  return (
    <>
      <label className={`text-sm my-2 block ${className}`}>
        {label}
        <BasicInput
          disabled={disabled}
          type={inputMode || "text"}
          maxLength={maxLength}
          minLength={minLength}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          name={name}
          pattern={pattern}
          onInput={internalOnInput}
          required={required ?? false}
          testId={testIdKey}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          className={inputClassName}
        />
        {showValidation ?? true ? (
          <div
            style={{ display: validationMessage === null ? "none" : "block" }}
            className="text-snow-red text-sm"
            data-testid={testIdKey + "-validation-message"}
          >
            {validationMessage}
          </div>
        ) : null}
      </label>
    </>
  );
}

export default AuthLabeledTextInput;
