import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { connect, useDispatch } from "react-redux";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeContext";

// Components
import LoadingIcon from "../../components/Loaders/LoadingIcon";
import AdminSideNavBar from "../../components/Navigation/AdminSideNavBar";
import AdminTopNavBar from "../../components/Navigation/AdminTopNavBar";

// Redux

// Services
import FlashMessage from "../../components/FlashMessage";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import ForceCompanySelection from "../../components/ForceCompanySelection";

const AdminWrapper = (props) => {
  const dispatch = useDispatch();

  const [showLoading, setShowLoading] = useState(true);
  const [user] = useAuthState(firebaseAuth.auth);

  useEffect(() => {
    // When user object has loaded from firebase, display.
    // Required for authorization - not requesting data before fb user.idToken is loaded
    if (user) {
      setShowLoading(false);
    }
  }, [user]);

  return (
    <div className="theme-light">
      <ThemeContext.Provider value={{ theme: "light" }}>
        <AdminTopNavBar />
        <AdminSideNavBar />
        <FlashMessage />

        {showLoading ? (
          <div className="flex justify-center">
            <LoadingIcon />
          </div>
        ) : (
          <>
            <div className="pt-20 print:pt-0 sm:pl-56 print:pl-0 absolute top-0 bottom-0 left-0 right-0">
              <ForceCompanySelection
                currentCompanyId={props.currentUser.currentCompanyId}
                currentUser={props.currentUser}
              >
                <Outlet />
              </ForceCompanySelection>
            </div>
          </>
        )}
      </ThemeContext.Provider>
      <ScrollRestoration />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { metadata, currentUser } = state;
  return { metadata, currentUser };
};

export default connect(mapStateToProps)(AdminWrapper);
