import React from "react";
import WithSnowFlakes from "../../components/LandingPage/WithSnowFlakes";
import SplitFormSmall, { CenteredLogo } from "../../components/SplitFormSmall";
import AppLinks from "./components/AppLinks";

const InstallInstructions = () => {
  const OS = getMobileOperatingSystem();
  if (OS === "Android") {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.trayl.dev.snow";
  }
  if (OS === "iOS") {
    window.location.href =
      "https://apps.apple.com/us/app/snowscape-management/id6443944347";
  }

  return (
    <WithSnowFlakes>
      <div className="flex flex-col w-full items-center px-4 py-2 overflow-scroll ">
        <div className="w-full max-w-[1200px]">
          <SplitFormSmall
            leftContent={
              <>
                <div>
                  <CenteredLogo />
                  <div className="text-snow-white text-2xl text-left mt-10">
                    Welcome to SnowScape! To get started, download our mobile
                    app and login with your phone number.
                  </div>
                </div>
              </>
            }
          >
            <div className="grid h-full">
              <div className="text-full-white place-self-center py-20 flex flex-row gap-5">
                <AppLinks />
              </div>
            </div>
          </SplitFormSmall>
        </div>
      </div>
    </WithSnowFlakes>
  );
};

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}
export default InstallInstructions;
