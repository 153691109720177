import React from "react";
import LoadingIcon from "./Loaders/LoadingIcon";
import { current } from "@reduxjs/toolkit";
import { auth } from "@snowscape/snow-lib";
import { useDispatch } from "react-redux";
import { setCurrentCompany } from "../store/reducers/currentUserSlice";
import SubtleButton from "./Buttons/SubtleButton";
export default function ForceCompanySelection({
  children,
  currentUser,
  currentCompanyId,
}) {
  const dispatch = useDispatch();
  console.log(currentUser);
  if (currentCompanyId) {
    return children;
  }
  let viewableCompanies = Object.values(currentUser.companies).filter(
    (c) => c.active && auth.canAdminCompany(currentUser, c.companyId),
  );
  let companies = Object.values(currentUser.companies);
  return (
    <div className="">
      <div className="flex flex-col items-center gap-6">
        <div className="max-w-[600px] flex items-center flex-col gap-6">
          <h1 className="mt-4 text-4xl font-bold">Pick a Company</h1>
          <p className="text-description-gray">
            Select the company that you want to work on today:
          </p>
          {companies.map((c, key) => (
            <div
              key={key}
              className={`${c.active !== false && auth.canAdminCompany(currentUser, c.companyId) ? "cursor-pointer" : "opacity-50 cursor-not-allowed"} w-full bg-snow-extra-light-grey p-5 border-[#cccccc] border rounded-md`}
              onClick={() => {
                dispatch(setCurrentCompany(c.companyId));
              }}
            >
              <span className="font-bold">{c.companyName}</span>
              {c.active !== false ? null : <p>Company is inactive</p>}
              {auth.canAdminCompany(currentUser, c.companyId) ? null : (
                <p>Mobile App Access Only</p>
              )}
            </div>
          ))}
          {companies.length < 1 || viewableCompanies < 1 ? (
            <>
              <p>
                Looks like you are not currently a member of any active company
                using SnowScape. Would you like to signup so you can create your
                own companies?
              </p>
              <SubtleButton
                onClick={() => {
                  window.location.href = "/sign-up";
                }}
              >
                Sign Up for SnowScape
              </SubtleButton>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
