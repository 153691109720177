import { CardElement } from "@stripe/react-stripe-js";
import React, { useCallback, useState } from "react";
import CardForm from "./CardForm";
import PulseLoader from "react-spinners/PulseLoader";
import { confirmWrapper } from "../Modals/ConfirmWrapper";
import axios from "axios";
import flash from "../../services/flash";
import errors from "../../services/errors";
import SubtleButton from "../Buttons/SubtleButton";
import { format } from "date-fns";

/**
 * @typedef BillingInformation
 * @property {string} cardExpirationDate
 * @property {string} cardLastFour
 * @property {string} customerId
 * @property {string} nameOnCard
 * @property {string} nextChargeAmount
 * @property {string} type
 */

/**
 *
 * @param {{billingInfo: BillingInformation|undefined}} props
 */
export default function PaymentMethod({
  currentUser,
  onChange,
  paymentMethods,
  defaultPaymentMethodId,
}) {
  const [showAddForm, setShowAddForm] = useState(false);
  const removePaymentMethod = useCallback(
    async (id) => {
      if (
        await confirmWrapper("Remove Payment Method?", {
          okText: "Remove",
          description: "Are you sure you want to remove this payment method?",
        })
      ) {
        try {
          await axios.post("stripe/customer/payment-method/remove", {
            paymentMethodId: id,
          });
          flash.success("Payment method removed.");
        } catch (err) {
          errors.report(err, { message: "unable to remove payment method" });
          flash.error(
            "Unable to remove this payment method.  Please contact support and we will help you.",
          );
        } finally {
          onChange();
        }
      }
    },
    [onChange],
  );
  let methods = paymentMethods ?? [];
  return (
    <>
      <div className="flex flex-col gap-2">
        {methods.length > 0 ? (
          methods.map((pm) => (
            <>
              <div className="grid grid-cols-2">
                <div className="flex gap-2 items-center">
                  <h2 className="text-xl capitalize">
                    {pm.card?.brand ?? pm.type}
                  </h2>
                  {pm.type === "card" ? (
                    <>
                      <span>•••• {pm.card?.last4}</span>
                      <span>{pm.billing_details?.name}</span>
                    </>
                  ) : null}
                  {pm.id === defaultPaymentMethodId ? (
                    <span className="bg-snow-extra-light-grey rounded-md p-1 font-bold text-xs">
                      Default
                    </span>
                  ) : null}
                </div>
                <div style={{ justifySelf: "end" }}>
                  {pm.type === "card" ? (
                    <span className="text-description-gray text-xs mx-2">
                      Expires{" "}
                      {format(
                        new Date(
                          pm.card.exp_year,
                          pm.card.exp_month,
                          0,
                          0,
                          0,
                          0,
                        ),
                        "MMM yyyy",
                      )}
                    </span>
                  ) : null}
                  <SubtleButton
                    onClick={() => removePaymentMethod(pm.id)}
                    className="self-center justify-self-end hover:border-snow-red hover:text-snow-red"
                  >
                    Remove
                  </SubtleButton>
                </div>
              </div>
            </>
          ))
        ) : (
          <span>No Payment Methods</span>
        )}
      </div>

      {showAddForm ? (
        <div
          className={`my-2 shadow-lg w-[337px] h-[212px] from-[#3274f0] to-[#9ec0f7] bg-gradient-to-br text-[#bac3c8] rounded-xl p-5 `}
        >
          <CardForm
            currentUser={currentUser}
            onSuccess={() => {
              setShowAddForm(false);
              onChange();
            }}
          />
        </div>
      ) : (
        <SubtleButton
          onClick={() => setShowAddForm(true)}
          className="row-span-3 justify-self-center self-center "
        >
          + Add Payment Method
        </SubtleButton>
      )}
    </>
  );
}
function CardButton({ children, onClick, className, disabled, loading }) {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`p-2 rounded-md border border-full-white text-full-white disabled:text-landing-page-gray disabled:border-landing-page-gray font-bold ${className}`}
    >
      {loading ? (
        <PulseLoader
          color={"#fffafa"}
          size={5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        children
      )}
    </button>
  );
}
export { CardButton };
