import React from "react";
import { connect, useDispatch } from "react-redux";
import HeaderBar from "../../components/Header/HeaderBar";
import ContentWrapper from "../../components/ContentWrapper";
import PhoneNumberInput, {
  normalizePhoneNumberValue,
} from "../../components/Inputs/PhoneNumberInput";
import SubtleButton from "../../components/Buttons/SubtleButton";
import axios from "axios";
import { updateUser } from "../../store/reducers/currentUserSlice";
import flash from "../../services/flash";

const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";

const SnowScapeAdmin = () => {
  const dispatch = useDispatch();
  const impersonate = async (phoneNumber) => {
    try {
      await axios.post("/user/impersonate", { phone: phoneNumber });
    } catch (err) {
      if (err.response?.data?.message) {
        flash.error(err.response.data.message);
      }
    }
    let userResponse = await axios.get("/user/me");
    await dispatch({ type: "SIGNOUT_CLEAR_STORE" });
    await dispatch(
      updateUser({
        ...userResponse.data.user,
        is_impersonating: userResponse.data.is_impersonating ?? false,
      }),
    );
    // window.location.reload();
  };

  return (
    <>
      <HeaderBar title="SnowScape Dashboard" />
      <ContentWrapper>
        <div className="grid grid-cols-3 gap-2">
          <section className={sectionStyle}>
            <h2>Log In As User</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                impersonate(normalizePhoneNumberValue(e.target.phone.value));
              }}
            >
              <PhoneNumberInput required={true} />
              <SubtleButton
                className="self-center justify-self-end"
                type="submit"
              >
                Switch
              </SubtleButton>
            </form>
          </section>
        </div>
      </ContentWrapper>
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(SnowScapeAdmin);
