import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Resources
import UnlockWhiteIcon from "../../resources/unlock-white.svg";
import features from "../../utils/features";
import { auth } from "@snowscape/snow-lib";
import SwitchCompanyDropdown from "../Inputs/SwitchCompanyDropdown";

const developerSection = {
  title: "Developer",
  options: [
    {
      name: "Push Notifications",
      redirectTo: "/admin/developer/broadcast-push-notification",
    },
    {
      name: "SnowScape Admin",
      redirectTo: "/admin/snowscape",
    },
  ],
};

const AdminSideNavBar = (props) => {
  const dispatch = useDispatch();

  const manageSection = {
    title: "Manage",
    options: [
      { name: "Routes", redirectTo: "/admin/route" },
      { name: "Active Routes", redirectTo: "/admin/active-routes" },
      { name: "Properties", redirectTo: "/admin/property" },
      { name: "Property History", redirectTo: "/admin/property/history" },
      { name: "Time Clock", redirectTo: "/admin/timeclock" },
      { name: "Employees", redirectTo: "/admin/employee" },
      { name: "Services", redirectTo: "/admin/company/services" },
      { name: "Materials", redirectTo: "/admin/material" },
      { name: "Reports", redirectTo: "/admin/report" },
      {
        name: "Settings / Account",
        redirectTo: "/admin/settings/company",
        options: [
          { name: "Company", redirectTo: "/admin/settings/company" },
          { name: "Billing", redirectTo: "/admin/settings/billing" },
          { name: "Profile", redirectTo: "/admin/settings/profile" },
        ],
      },
    ],
  };
  features.refresh();
  return (
    <nav className="hidden sm:flex flex-col w-56 fixed top-20 bottom-0 left-0 bg-snow-primary z-30  overflow-y-scroll print:hidden">
      <div className="p-1">
        <p className="text-snow-white text-xs mt-2">Current Company</p>
        <SwitchCompanyDropdown />
      </div>
      <div className="flex flex-col justify-between w-full flex-1">
        {props.currentUser.currentCompanyId ? (
          <SideBarSection section={manageSection} />
        ) : null}
        {auth.canSuperAdmin(props.currentUser) ? (
          <SideBarSection section={developerSection} />
        ) : null}
      </div>
      <div className="flex flex-row w-full justify-center mb-6 hover:opacity-60 active:opacity-40">
        <img alt="upgrade" className="w-6 h-6" src={UnlockWhiteIcon} />
        <Link
          className="text-center text-snow-white font-bold text-md ml-2"
          to="/admin/settings/billing?show-plans=1"
        >
          Upgrade Subscription
        </Link>
      </div>
    </nav>
  );
};

const SideBarSection = (props) => {
  return (
    <div className="flex flex-1 flex-col mt-10 text-snow-white">
      <div className="border-b-2 w-full px-5 opacity-80 text-base	">
        {props.section.title}
      </div>
      <div className="flex flex-col mx-11 font-bold">
        {props.section.options.map((option, key) => (
          <div key={key} className="my-1">
            <Link className="hover:opacity-60" key={key} to={option.redirectTo}>
              {option.name}
            </Link>
            <ol className="sub-menu ml-4 list-disc text-sm">
              {option.options?.map((subOption) => (
                <li className="" key={subOption.name}>
                  <Link to={subOption.redirectTo}>{subOption.name}</Link>
                </li>
              ))}
            </ol>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser, metadata } = state;
  return { currentUser, metadata };
};

export default connect(mapStateToProps)(AdminSideNavBar);
