import React, { useEffect, useState } from "react";
import axios from "axios";
import errors from "../services/errors";
import flash from "../services/flash";
import { auth, authConstants } from "@snowscape/snow-lib";
import CheckBox from "./Inputs/CheckBox";

const allPossiblePermissions = authConstants.NORMAL_PRIVILEGES;
/**
 *
 * @param props
 */
function UserPermissions({ userToEdit, companyId }) {
  const checkedPermissions = userToEdit.permissions[companyId];

  return (
    <>
      <h3 className="text-lg font-bold">Permissions</h3>
      {auth.canSuperAdmin(userToEdit) ? (
        <p>
          This user is a super-admin so they have full access to all data /
          permissions.
        </p>
      ) : (
        <>
          <p className="text-sm text-snow-primary font-light mb-1">
            All employees can use the mobile app to perform basic actions. You
            can also add extra permissions to this user by checking any of the
            options below.
          </p>
          {allPossiblePermissions.map((perm, key) => (
            <div
              className={`capitalize ${perm === "view" ? "hidden" : ""}`}
              key={key}
            >
              <CheckBox
                label={perm.replaceAll("-", " ")}
                name={`permissions-${perm}`}
                defaultChecked={checkedPermissions.includes(perm)}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
}
function gatherPermissions(form) {
  const newPerms = [];
  for (const perm of allPossiblePermissions) {
    if (form[`permissions-${perm}`].checked) {
      newPerms.push(perm);
    }
  }
  return newPerms;
}

export default UserPermissions;
export { gatherPermissions };
