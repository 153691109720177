import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ContentWrapper from "../../../components/ContentWrapper";
import { useQuery } from "@tanstack/react-query";
import { DashboardButton } from "../Dashboard/DashboardPage";
import AppLinks from "../../LandingPage/components/AppLinks";
import { auth } from "@snowscape/snow-lib";
const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";
const NewEmployeeInstructionsPage = ({ currentCompanyId }) => {
  let { id } = useParams();

  const userQuery = useQuery({
    queryKey: [`user/by-id/${id}`],
  });

  return (
    <>
      <ContentWrapper loading={userQuery.isLoading}>
        {userQuery.isLoading ? null : (
          <>
            <div
              className={`purchaseSuccess ${sectionStyle} flex flex-col items-center`}
              data-testid="purchase-success"
            >
              <svg
                width="100"
                height="100"
                version="1.1"
                viewBox="0 0 26.458 26.458"
                className="text-snow-green w-[100px] h-[100px] my-6"
              >
                <g transform="translate(0 -270.54)">
                  <path
                    d="m13.229 270.54a13.229 13.229 0 0 0-13.229 13.229 13.229 13.229 0 0 0 13.229 13.229 13.229 13.229 0 0 0 13.229-13.229 13.229 13.229 0 0 0-13.229-13.229zm4.0281 8.843c0.31888 0.0106 0.63618 0.12826 0.89528 0.35673 0.59223 0.52222 0.64894 1.4192 0.12672 2.0114l-5.1243 5.8113-0.09678 0.10979c-0.35902 0.40711-0.89529 0.56115-1.3878 0.44786-0.08957-0.0206-0.17763-0.0502-0.26299-0.0885-0.12804-0.0575-0.24959-0.13513-0.36063-0.23304l-2.741-2.4172c-0.59223-0.52222-0.64893-1.4192-0.12672-2.0115l0.096776-0.10979c0.52222-0.59223 1.4192-0.64851 2.0115-0.12629l1.6721 1.4746 4.1817-4.7424c0.29375-0.33313 0.70619-0.49659 1.1162-0.48301z"
                    fill="currentColor"
                  />
                </g>
              </svg>
              <div className="flex flex-col max-w-[600px]">
                <h2 className="text-3xl font-semibold">
                  {userQuery.data.firstName} {userQuery.data.lastName} is ready
                  to go!
                </h2>
                <span className="text-description-gray">
                  {userQuery.data.firstName} can start recording data by
                  downloading the SnowScape app and logging in with their phone
                  number: {userQuery.data.phoneNumber}.<br />
                  We have just sent them a text message with links to install
                  SnowScape.
                </span>
                <div className="flex flex-row gap-2">
                  <AppLinks />
                </div>
                {auth.can(userQuery.data, "admin", currentCompanyId) ? (
                  <>
                    <span className="text-description-gray">
                      {userQuery.data.firstName} can also access the web portal
                      by{" "}
                      <a href="/admin-login" className="underline">
                        logging in
                      </a>{" "}
                      on our website.
                    </span>
                  </>
                ) : null}
                <div className="flex flex-row gap-2  mt-2">
                  <DashboardButton to="/admin/employee/new">
                    Add Another Employee
                  </DashboardButton>
                  <DashboardButton to="/admin/employee">
                    Employee List
                  </DashboardButton>
                </div>
              </div>
            </div>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export default connect((state) => {
  return {
    currentCompanyId: state.currentUser.currentCompanyId,
  };
})(NewEmployeeInstructionsPage);
export { NewEmployeeInstructionsPage };
