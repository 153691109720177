import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmployeeCard from "../../../components/Cards/EmployeeCard";
import FilterBar from "../../../components/Filters/FilterBar";
import HeaderBar from "../../../components/Header/HeaderBar";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import InviteBySMSModal from "../../../components/Modals/InviteBySMSModal";
import NotificationModal from "../../../components/Modals/NotificationModal";
import { useQuery } from "@tanstack/react-query";
import axiosCompanies from "../../../services/axios/companies";
import ContentWrapper from "../../../components/ContentWrapper";
import { auth } from "@snowscape/snow-lib";
import Usage from "../Account/Usage";
import { confirmWrapper } from "../../../components/Modals/ConfirmWrapper";
const AdminEmployeePage = (props) => {
  const navigate = useNavigate();

  const [currentFilter, setCurrentFilter] = useState("all");
  const [displayInviteModal, setDisplayInviteModal] = useState(false);
  const [displayInviteSuccessModal, setDisplayInviteSuccessModal] =
    useState(false);
  const [employees, setEmployees] = useState([]);

  const [loading, setLoading] = useState(true);

  const userQuery = useQuery({
    queryKey: [
      `company/${props.currentUser.currentCompanyId}/users?take=10000`,
    ],
  });
  const companyQuery = useQuery({
    queryKey: [`company/${props.currentUser.currentCompanyId}`],
  });
  const usageQuery = useQuery({
    queryKey: [`company/${props.currentUser.currentCompanyId}/usage`],
  });

  useMemo(() => {
    // setLoading(true);
    // setEmployees([]);
    // axiosCompanies.getAllUsers(
    //   { companyId: props.currentUser.currentCompanyId },
    //   (employees) => {
    //     setEmployees(employees);
    //     setLoading(false);
    //   },
    // );
  }, [props.currentUser.currentCompanyId]);

  const isActive = (userId) => {
    return companyQuery.isSuccess
      ? companyQuery.data.authorizedUsers[userId]?.active ?? false
      : true; // Default to true until we know
  };
  return (
    <>
      <HeaderBar
        buttons={[
          {
            color: "blue",
            label: "Add",
            disabled: usageQuery.isLoading,
            onClick: async () => {
              if (
                usageQuery.data.activeUsers >= usageQuery.data.authorizedUsers
              ) {
                if (
                  await confirmWrapper(
                    "Please upgrade your plan to add more employees.",
                    {
                      okText: "Go to Plan Settings",
                      description:
                        "Before you can add more employees, you will need to upgrade your subscription. Please go to Settings > Billing and switch plans.",
                    },
                  )
                ) {
                  return navigate("/admin/settings/billing");
                }
              } else {
                navigate("/admin/employee/new");
              }
            },
          },
        ]}
        title="Employees"
      />
      <ContentWrapper loading={userQuery.isLoading}>
        {userQuery.isLoading ? null : (
          <div className="users text-description-gray flex flex-col">
            <div className="grid grid-cols-2 bg-[#eeeeee]  p-2 font-semibold">
              <span>User</span>
              <span>Access</span>
            </div>
            {userQuery.data.map((user) => (
              <div
                className={`user border-b py-2 border-[#e4e4e4] grid grid-cols-2 cursor-pointer ${isActive(user._id) ? null : "opacity-35"}`}
                key={user._id}
                onClick={() => navigate(`/admin/employee/${user._id}`)}
              >
                <div className="flex flex-col">
                  <span className={`font-semibold text-full-black`}>
                    {user.firstName} {user.lastName}{" "}
                    {isActive(user._id) ? null : (
                      <span className="text-description-gray">(inactive)</span>
                    )}
                  </span>
                  <span>{user.phoneNumber}</span>
                </div>
                <div>
                  {auth
                    .getPermissionsForCompany(
                      user,
                      props.currentUser.currentCompanyId,
                    )
                    .map((perm) => (
                      <span
                        key={perm}
                        className="px-2 py-1 bg-[#e9fdf2] border border-[#bfefd1] rounded-full mr-1 text-sm"
                      >
                        {perm}
                      </span>
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}
        {usageQuery.isLoading ? null : (
          <div className="mt-5">
            <Usage
              total={usageQuery.data.authorizedUsers}
              using={usageQuery.data.activeUsers}
              name="Allowed Employees"
              showUpgradeLink={true}
            ></Usage>
          </div>
        )}
      </ContentWrapper>
      {/* {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
            <FilterBar
              currentFilter={currentFilter}
              filters={[
                { label: "All", value: "all" },
                { label: "Admins Only", value: "admin" },
              ]}
              setCurrentFilter={setCurrentFilter}
            />

            <div className="w-full">
              {employees.map((employee) =>
                currentFilter === "all" ||
                currentFilter ===
                  employee.companies[props.currentUser.currentCompanyId]
                    .role ? (
                  <EmployeeCard
                    key={employee._id}
                    employee={employee}
                    currentCompanyId={props.currentUser.currentCompanyId}
                    onClick={() => {
                      employee.uid
                        ? navigate(employee.uid)
                        : navigate(`/admin/timeclock/employee/${employee._id}`);
                    }}
                  />
                ) : null,
              )}
            </div>
          </div>
        )} */}
      <InviteBySMSModal
        adjustForSidebar
        onClose={() => setDisplayInviteModal(false)}
        open={displayInviteModal}
        onSuccess={() => setDisplayInviteSuccessModal(true)}
      />
      <NotificationModal
        adjustForSidebar
        button={{
          color: "blue",
          label: "Okay",
          onClick: () => {
            // Close
            setDisplayInviteSuccessModal(false);
          },
        }}
        open={displayInviteSuccessModal}
        subtitle="Your message has been sent."
        title="Success"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminEmployeePage);
