import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import HeaderBar from "../../../components/Header/HeaderBar";
import UserPermissions, {
  gatherPermissions,
} from "../../../components/UserPermissions";
import ContentWrapper from "../../../components/ContentWrapper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import flash from "../../../services/flash";
import { ObjectId } from "bson";
import Select from "react-select";
import AuthLabeledTextInput from "../../../components/Inputs/AuthLabeledTextInput";
import CheckBox from "../../../components/Inputs/CheckBox";
import { useRef } from "react";
import PhoneNumberInput, {
  normalizePhoneNumberValue,
} from "../../../components/Inputs/PhoneNumberInput";
import { confirmWrapper } from "../../../components/Modals/ConfirmWrapper";

const AdminEmployeeDetailsPage = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();

  const makeNew = id === "new";

  const [employee, setEmployee] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [employeeLoading, setEmployeeLoading] = useState(true);

  const isSelf = props.currentUser.uid == employee.uid;

  const queryClient = useQueryClient();
  const companyQuery = useQuery({
    queryKey: [`company/${props.currentUser.currentCompanyId}`],
  });
  const usageQuery = useQuery({
    queryKey: [`company/${props.currentUser.currentCompanyId}/usage`],
    enabled: makeNew,
  });
  const saveEmployeeMutation = useMutation({
    mutationFn: (employeeData) => {
      return axios.post(`user/update-or-create/${employeeData._id}`, {
        ...employeeData,
        companyId: props.currentUser.currentCompanyId,
      });
    },
    onSuccess: (resp) => {
      flash.success(resp?.data?.message ?? "User saved");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.find(
            (key) =>
              key.includes("company") ||
              key.includes("companies") ||
              key.includes("user"),
          ) !== undefined,
      });
    },
    onError: (err) => {
      let details = err.response?.data;
      console.log(details);
      flash.error("Error saving: " + (details.message ?? details));
    },
  });
  const removeFromCompanyMutation = useMutation({
    mutationFn: (user) => {
      return axios.post(`user/${user._id}/remove-from-company`, {
        companyId: props.currentUser.currentCompanyId,
      });
    },
    onSuccess: () => {
      flash.success("User removed from this company");
      navigate("/admin/employee");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.find(
            (key) =>
              key.includes("company") ||
              key.includes("companies") ||
              key.includes("user"),
          ) !== undefined,
      });
    },
    onError: (err) => {
      let details = err.response?.data;
      console.log(details);
      flash.error("Error removing user: " + (details.message ?? details));
    },
  });

  const loading =
    employeeLoading || companyQuery.isLoading || usageQuery.isLoading;

  const formRef = useRef(null);
  useEffect(() => {
    if (makeNew) {
      setEmployee({
        _id: new ObjectId().toString(),
      });
      setEmployeeLoading(false);
    } else {
      setEmployeeLoading(true);
      axios
        .get(`user/by-id/${id}`)
        .then((res) => setEmployee(res.data))
        .finally(() => setEmployeeLoading(false));
    }
  }, [id, makeNew]);

  const employeeActive =
    companyQuery.data?.authorizedUsers[id]?.active ?? false;

  let title = "Employee Details";
  if (makeNew) {
    title = "New Employee";
  } else if (employee.firstName && employee.lastName) {
    title = `${employee.firstName} ${employee.lastName}`;
  }
  const onSubmit = useCallback(
    async (e) => {
      console.log("calling on submit");
      try {
        setIsSaving(true);
        const form = e.nativeEvent.target;
        let newData = {
          _id: form.id?.value,
          firstName: form.first_name?.value,
          lastName: form.last_name?.value,
          phoneNumber: normalizePhoneNumberValue(form.phone?.value),
          active: makeNew ? true : form.active?.checked,
          permissions: makeNew ? ["view"] : gatherPermissions(form),
        };
        console.log(newData);
        try {
          const resp = await saveEmployeeMutation.mutateAsync(newData).catch();
          if (makeNew) {
            navigate(`/admin/employee/${resp.data.user._id}/instructions`);
          } else {
            navigate(`/admin/employee`);
          }
        } catch (err) {
          // pass - handled in mutation onError
        }
      } finally {
        setIsSaving(false);
      }
    },
    [makeNew, navigate, saveEmployeeMutation],
  );
  if (
    makeNew &&
    usageQuery.isSuccess &&
    usageQuery.data.activeUsers >= usageQuery.data.authorizedUsers
  ) {
    return (
      <>
        <p>To add more users, please upgrade your subscription</p>
      </>
    );
  }

  let buttons = [];

  console.log(props.currentUser);
  console.log(employee._id);
  if (!makeNew && !isSelf) {
    buttons.push({
      color: "red",
      label: "Remove",
      onClick: async () => {
        if (
          await confirmWrapper("Remove Employee?", {
            okText: "Remove",
            description:
              "Once removed this employee will no longer have access to this company.\nIf you might add this employee again later, consider deactivating them instead.",
          })
        ) {
          removeFromCompanyMutation.mutate(employee);
        }
      },
    });
  }
  buttons.push({
    color: "green",
    label: "Save",
    loading: isSaving,
    onClick: async () => {
      formRef.current.requestSubmit();
    },
  });

  return (
    <>
      <HeaderBar buttons={buttons} title={title} />
      <ContentWrapper loading={loading}>
        {loading ? null : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(e);
            }}
            ref={formRef}
          >
            <input type="hidden" name="id" value={employee._id} />
            <input type="submit" hidden />
            {/* this allows enter to submit the form */}
            <AuthLabeledTextInput
              label="First Name"
              defaultValue={employee.firstName}
              name="first_name"
              required={true}
            />
            <AuthLabeledTextInput
              label="Last Name"
              defaultValue={employee.lastName ?? ""}
              name="last_name"
              required={true}
            />
            <PhoneNumberInput
              required={true}
              disabled={!makeNew}
              defaultValue={employee.phoneNumber ?? ""}
            />
            {makeNew ? (
              <>
                <div className={`text-sm font-bold mb-0.5 text-snow-primary`}>
                  Employee Type
                </div>
                <Select
                  name="active"
                  onChange={(val) =>
                    setEmployee({ ...employee, active: val.value })
                  }
                  options={EmployeeTypeOptions}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: "#829399",
                    }),
                  }}
                  value={
                    employee.active === false
                      ? EmployeeTypeOptions.find((o) => o.value === false)
                      : EmployeeTypeOptions.find((o) => o.value === true)
                  }
                />
              </>
            ) : (
              <>
                <CheckBox
                  name="active"
                  defaultChecked={employeeActive}
                  label="Active"
                  disabled={isSelf}
                  description="Active employees can login and use the app.  Inactive employees can only be used in the time clock feature. Only active employees count towards your usage limits."
                />
                {!makeNew ? (
                  <UserPermissions
                    userToEdit={employee}
                    companyId={props.currentUser.currentCompanyId}
                  />
                ) : null}
              </>
            )}
          </form>
        )}
      </ContentWrapper>
    </>
  );
};
const EmployeeTypeOptions = [
  { value: true, label: "Normal Employee (Mobile App Access)" },
  { value: false, label: "Time Clock Only (No Mobile App Access)" },
];

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminEmployeeDetailsPage);
export { AdminEmployeeDetailsPage };
