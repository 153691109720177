import React from "react";
import { Link } from "react-router-dom";
export default function Usage({
  using = 0,
  total = 0,
  name,
  loading,
  showUpgradeLink = false,
  className,
}) {
  const tooMany = using > total;
  return (
    <div
      data-testid="usage"
      className={`bg-snow-extra-light-grey rounded-md p-2 text-sm inline-flex flex-row gap-3 ${tooMany ? "text-snow-red" : ""} ${loading ? "text-transparent ui-placeholder" : ""} ${className ?? ""}`}
    >
      <div>
        {using} / {total}
      </div>
      <div
        className={`${loading ? "text-transparent" : "text-description-gray"}`}
      >
        {name}{" "}
        {showUpgradeLink && using >= total ? (
          <Link to="/admin/settings/billing?show-plans=1" className="underline">
            Upgrade Options
          </Link>
        ) : null}
      </div>
    </div>
  );
}
