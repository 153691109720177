import React from "react";
import AuthLabeledTextInput from "../../components/Inputs/AuthLabeledTextInput";
import { useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseAuth from "../../services/firebase/firebaseAuth";
import PhoneNumberInput from "../../components/Inputs/PhoneNumberInput";
export default function PersonalInfoFields({ currentUser }) {
  const [searchParams] = useSearchParams();
  const [firebaseUser, authLoading] = useAuthState(firebaseAuth.auth);

  if (authLoading) return null;
  return (
    <>
      {currentUser?.uid && firebaseUser ? null : (
        <div className="flex flex-col sm:flex-row gap-2">
          <AuthLabeledTextInput
            label="First Name"
            maxLength={30}
            placeholder="John"
            name="first_name"
            className="my-0"
            required
          />
          <AuthLabeledTextInput
            label="Last Name"
            name="last_name"
            maxLength={20}
            placeholder="Doe"
            className="my-0"
            required
          />
        </div>
      )}
      <AuthLabeledTextInput
        label="Email Address"
        name="email"
        maxLength={200}
        placeholder="you@example.com"
        className="my-0"
        required
      />
      {firebaseUser?.phoneNumber ? (
        <input type="hidden" name="phone" value={firebaseUser.phoneNumber} />
      ) : (
        <PhoneNumberInput required />
      )}
    </>
  );
}
