import React from "react";
import RoundButton from "../Buttons/RoundButton";
import ModalWrapper from "./ModalWrapper";
import PropTypes from "prop-types";
import { confirmable } from "react-confirm";
import SubtleButton from "../Buttons/SubtleButton";
const YourDialog = ({ show, proceed, confirmation, options = {} }) => {
  if (show) {
    return (
      <ModalWrapper
        clickOff={() => {
          proceed(false);
        }}
      >
        <div
          className={`flex flex-col  rounded-xl bg-snow-white drop-shadow-2xl px-12 pt-6 pb-6 overflow-x-scroll`}
        >
          <p className="text-2xl font-bold font-snow-primary mb-2">
            {confirmation ?? "Are you sure?"}
          </p>
          <p className="mb-2 max-w-[600px] whitespace-pre-wrap">
            {options.description ?? ""}
          </p>
          <div className="flex flex-row flex-1 w-full gap-4 justify-end">
            <SubtleButton
              onClick={() => proceed(false)}
              color="blue"
              label={options.cancelText ?? "Cancel"}
            ></SubtleButton>
            <SubtleButton
              onClick={() => proceed(true)}
              buttonRole="primary"
              label={options.okText ?? "Ok"}
            ></SubtleButton>
          </div>
        </div>
      </ModalWrapper>
    );
  }
  return null;
};

YourDialog.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string, // arguments of your confirm function
  options: PropTypes.object, // arguments of your confirm function
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(YourDialog);
