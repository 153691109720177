import React from "react";
import PricingCard from "../pages/LandingPage/components/PricingCard";
import { Link } from "react-router-dom";

const PricingData = [
  {
    name: "Han Solo",
    id: "hansolo-free-sub",
    companies: 1,
    users: 1,
    price: 0,
  },
  {
    name: "Team",
    id: "team-sub",
    companies: 1,
    users: 20,
    price: 89,
    default: true,
  },
  {
    name: "Squad",
    id: "squad-sub",
    companies: 2,
    users: 100,
    price: 179,
  },
  {
    name: "Fleet",
    id: "fleet-sub",
    companies: 5,
    users: 300,
    price: 269,
  },
];
const AddOnPricing = {
  extra_users: {
    name: "Extra Users",
    id: "extra_users",
    price: 11.99,
  },
  extra_companies: {
    name: "Extra Companies",
    id: "extra_companies",
    price: 49.99,
  },
};
const Pricing = ({ onPlanSelect, buttonText }) => {
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-4 mt-10">
        {PricingData.map((plan) => (
          <PricingCard
            key={plan.name}
            price={plan.price === 0 ? "free" : plan.price}
            title={plan.name}
            details={[plan.companies + " Company", plan.users + " User"]}
            subtext="Add extra users for $11.99 / user"
            onClick={() => onPlanSelect(plan.id)}
            buttonText={buttonText}
          />
        ))}
      </div>
      <div className="mx-auto mt-4 text-2xl font-light mb-8 text-center">
        Get started with a <b>7 day free trial</b> today!
        <br />
        Not seeing a tier that's right for you?{" "}
        <Link to="/pricing/quote" className="underline">
          Get a quote
        </Link>
        .
      </div>
      <p className="max-w-[800px] mx-auto text-sm">
        All our plans support adding extra users for $11.99 / user / month or
        extra companies at $49.99 / company / month. After signing up, you can
        modify your subscription to add extra users / companies - or just shoot
        us an email and we will do it for you!
      </p>
    </>
  );
};

export default Pricing;
export { PricingData, AddOnPricing };
