import React, { useCallback, useMemo, useRef, useState } from "react";
import flash from "../../services/flash";
import StandardButton from "../Buttons/StandardButton";
import UnsubscribeConfirmModal from "../Modals/UnsubscribeConfirmModal";
import UnsubscribeReasonModal from "../Modals/UnsubscribeReasonModal";
import axiosStripe from "../../services/axios/stripe";
import axiosContact from "../../services/axios/contact";
import axios from "axios";
import errors from "../../services/errors";
import TrashCanIcon from "../../resources/trash-can-red.svg";
import PencilIcon from "../../resources/pencil-blue.svg";
import SubtleButton from "../Buttons/SubtleButton";
import ChoosePlanInput, {
  buildPlanFromForm,
} from "../../pages/SignUp/ChoosePlanInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { stripePlans } from "@snowscape/snow-lib";
export default function Subscriptions({
  currentUser,
  subscriptionInformation,
  productInformation,
  onSubscriptionChange,
  beforeSubscriptionChange,
  hasPaymentMethod = true,
  onPaymentMethodNeeded,
  showChangePlanFormDefault = false,
}) {
  const [displayUnsubscribeModal, setDisplayUnsubscribeModal] = useState(false);
  const [displayUnsubscribeReasonModal, setDisplayUnsubscribeReasonModal] =
    useState(false);
  const [unsubscribeSubscriptionId, setUnsubscribeSubscriptionId] =
    useState(false);
  const formattedSubscriptions = useMemo(() => {
    return stripePlans.getPlansFromStripeSubscriptionData(
      subscriptionInformation,
      productInformation,
    );
  }, [productInformation, subscriptionInformation]);
  const [editSubscriptionId, setEditSubscriptionId] = useState(
    showChangePlanFormDefault && formattedSubscriptions.length === 1
      ? formattedSubscriptions[0].id
      : null,
  );
  const [showAddNewPlanForm, setShowAddNewPlanForm] = useState(false);
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();
  const changePlanMutation = useChangePlanMutation(currentUser);
  const subItems = useMemo(() => {
    // TODO: This doesn't quite reflect reality accurately since it implies that these items are separate subscriptions, which they might not be.
    let result = [];
    for (const sub of subscriptionInformation ?? []) {
      for (const sub_item of sub.items.data) {
        result.push({
          name:
            productInformation[sub_item.plan.product].metadata.name ??
            productInformation[sub_item.plan.product].name,
          allowedCompanies:
            Number(
              productInformation[sub_item.plan.product].metadata.companies ?? 0,
            ) * Number(sub_item.quantity),
          allowedUsers:
            Number(
              productInformation[sub_item.plan.product].metadata.users ?? 0,
            ) * Number(sub_item.quantity),
          startDate: new Date(sub.start_date * 1000),
          status: sub.cancel_at_period_end
            ? "End on " + new Date(sub.cancel_at * 1000).toLocaleDateString()
            : sub.status.charAt(0).toUpperCase() + sub.status.slice(1),
          id: sub.id,
        });
      }
    }
    return result;
  }, [productInformation, subscriptionInformation]);

  const handleUnsubscribe = useCallback(
    async (subscriptionId, message) => {
      try {
        // We don't want the unsubscribe to fail if the contact request fails - so lets wrap it in it's own try block:
        try {
          const customer = await axiosStripe.getCustomerById({
            uid: currentUser.uid,
          });

          await axiosContact.sendContactUsEmail({
            email: customer.email,
            message: `Subscription: ${subscriptionId} - Reason for cancellation: ${message}`,
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            type: "contact-us",
          });
        } catch (err) {
          errors.report(err);
        }

        await axios.post("stripe/subscription/unsubscribe", {
          subscriptionId,
          uid: currentUser.uid,
        });
        flash.success(
          "Successfully unsubscribed. Your subscription will end at the end of this billing period.",
        );
      } catch (err) {
        console.log(err);
        flash.error(err.message ?? err);
        errors.report(err);
      } finally {
        if (onSubscriptionChange instanceof Function) {
          onSubscriptionChange();
        }
      }
    },
    [currentUser, onSubscriptionChange],
  );

  const formRef = useRef(null);
  const submitChangePlan = useCallback(
    async (e) => {
      e.preventDefault();
      setSaving(true);
      try {
        let requestedPlan = buildPlanFromForm(e);
        if (
          typeof beforeSubscriptionChange !== "function" ||
          (await beforeSubscriptionChange(requestedPlan)) === true
        ) {
          await changePlanMutation
            .mutateAsync(requestedPlan, editSubscriptionId)
            .catch(() => {});
          if (onSubscriptionChange instanceof Function) {
            onSubscriptionChange();
          }
        }
      } finally {
        setEditSubscriptionId(null);
        setShowAddNewPlanForm(false);
        setSaving(false);
      }
    },
    [
      beforeSubscriptionChange,
      changePlanMutation,
      editSubscriptionId,
      onSubscriptionChange,
    ],
  );

  const editPlanButtons = (
    <>
      <SubtleButton
        disabled={saving}
        className="flex items-center gap-1"
        onClick={() => {
          setEditSubscriptionId(null);
          setShowAddNewPlanForm(false);
        }}
        testId="edit-subscription-cancel-button"
      >
        <span className="">Cancel Changes</span>
      </SubtleButton>
      <SubtleButton
        loading={saving}
        className="flex items-center gap-1"
        onClick={() => {
          if (!hasPaymentMethod) {
            alert("Please add a payment method first");
            if (typeof onPaymentMethodNeeded === "function") {
              onPaymentMethodNeeded();
            }
          } else {
            formRef.current.requestSubmit();
          }
        }}
        testId="save-subscription-button"
        buttonRole="primary"
      >
        {/* <img src={PencilIcon} width="23" height="23" /> */}
        <span className="">Save</span>
      </SubtleButton>
    </>
  );
  return (
    <>
      <ol>
        {formattedSubscriptions.map((sub, key) => (
          <li
            key={key}
            className="grid  grid-cols-[auto_1fr_auto] border-t border-[#cccccc] py-2 gap-y-2"
          >
            <div className="justify-self-start">
              <p>
                Subscription
                <span className={`${statusColor(sub.status)} text-sm ml-2`}>
                  {sub.status}
                </span>
              </p>
              <p className="text-description-gray text-[10px]">{sub.id}</p>
            </div>
            <span></span>
            <div className="flex flex-row justify-end gap-2 lg:gap-3 justify-self-end">
              {sub.id === editSubscriptionId ? (
                editPlanButtons
              ) : (
                <>
                  <SubtleButton
                    className="flex items-center gap-1"
                    onClick={() => {
                      setUnsubscribeSubscriptionId(sub.id);
                      setDisplayUnsubscribeModal(true);
                    }}
                    color="red"
                    testId="cancel-subscription"
                  >
                    <img src={TrashCanIcon} width="23" height="23" />
                    <span className="hidden lg:inline">Cancel</span>
                  </SubtleButton>
                  <SubtleButton
                    className="flex items-center gap-1"
                    onClick={() => {
                      if (!hasPaymentMethod) {
                        alert("Please add a payment method first");
                        if (typeof onPaymentMethodNeeded === "function") {
                          onPaymentMethodNeeded();
                        }
                      } else {
                        setEditSubscriptionId(sub.id);
                      }
                    }}
                    testId="edit-subscription-button"
                  >
                    <img src={PencilIcon} width="23" height="23" />
                    <span className="hidden lg:inline">Switch Plan</span>
                  </SubtleButton>
                </>
              )}
            </div>
            {sub.id === editSubscriptionId ? (
              <form
                ref={formRef}
                className={`col-span-3 ${saving ? "opacity-50" : ""}`}
                onSubmit={submitChangePlan}
              >
                <ChoosePlanInput
                  defaultExtraUsers={sub.extra_users}
                  defaultExtraCompanies={sub.extra_companies}
                  value={sub.plan ?? undefined}
                />
              </form>
            ) : (
              <>
                {sub.items.map((subItem, key) => (
                  <React.Fragment key={key}>
                    <div>
                      <div className="justify-self-start">
                        {subItem.quantity} {subItem.name}
                      </div>
                    </div>
                    <div className="justify-self-center text-xs">
                      <div>{subItem.allowedUsers} users</div>
                      <div>{subItem.allowedCompanies} companies</div>
                    </div>
                    <div className="text-sm justify-self-end">
                      {Intl.NumberFormat(undefined, {
                        style: "currency",
                        currency: subItem.currency,
                      }).format((subItem.price * subItem.quantity) / 100)}
                    </div>
                  </React.Fragment>
                ))}
              </>
            )}
          </li>
        ))}
      </ol>
      {subItems.length < 1 ? (
        <div>
          <p className="text-description-gray my-2">
            You are not subscribed to any plan
          </p>

          {showAddNewPlanForm ? (
            <form
              ref={formRef}
              className={` ${saving ? "opacity-50" : ""}`}
              onSubmit={submitChangePlan}
            >
              <ChoosePlanInput />
              <div className="flex flex-row justify-start gap-2 lg:gap-3 justify-self-end">
                {editPlanButtons}
              </div>
            </form>
          ) : (
            <StandardButton
              onClick={() => setShowAddNewPlanForm(true)}
              className="max-w-[300px]"
              disabled={!hasPaymentMethod}
            >
              Choose a Plan
              {hasPaymentMethod ? null : <div>(Add Payment Method First)</div>}
            </StandardButton>
          )}
        </div>
      ) : null}
      <UnsubscribeConfirmModal
        open={displayUnsubscribeModal}
        title="Cancel Subscription"
        subtitle="Are you sure you want to unsubscribe from this subscription?  Instead of canceling you can switch to our free plan and keep your data!"
        onCancel={() => {
          setDisplayUnsubscribeModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onEndSubscription={() => {
          setDisplayUnsubscribeModal(false);
          setDisplayUnsubscribeReasonModal(true);
        }}
      />
      <UnsubscribeReasonModal
        closeModal={() => {
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        onUnsubscribe={async (message) => {
          await handleUnsubscribe(unsubscribeSubscriptionId, message);
          setDisplayUnsubscribeReasonModal(false);
          setUnsubscribeSubscriptionId(null);
        }}
        open={displayUnsubscribeReasonModal}
      />
    </>
  );
}
const statusColor = (status) => {
  const finishedStatus = ["Declined", "Closed", "Inactive"];
  if (status in finishedStatus || status.includes("End on")) {
    return "text-snow-red";
  }
  if (status === "Late" || status === "Pending") {
    return "text-snow-red";
  }
  if (status === "Incomplete") {
    return "text-snow-yellow";
  }
  return "text-snow-green";
};
function useChangePlanMutation(currentUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (selectionData, editSubscriptionId) => {
      await axios.post("stripe/customer/update-subscription", {
        uid: currentUser.uid,
        plan: { lookup_key: selectionData.plan.id },
        sub_id: editSubscriptionId,
        addons: {
          extra_users: selectionData.extra_users,
          extra_companies: selectionData.extra_companies,
        },
      });
    },
    onSuccess: () => {
      flash.success("Success!  You have switched plans.");
      queryClient.invalidateQueries({
        queryKey: [`company/${currentUser.currentCompanyId}/in-good-standing`],
      });
    },
    onError: (err) => {
      console.log("error handler mutate");
      console.log(err);
      flash.error("Error: " + (err.message ?? err));
    },
  });
}
export { useChangePlanMutation };
