import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// Components
import HeaderBar from "../../../components/Header/HeaderBar";

// Services
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ContentWrapper from "../../../components/ContentWrapper";
import SwitchCompanyDropdown from "../../../components/Inputs/SwitchCompanyDropdown";
import Usage from "../Account/Usage";
import { auth } from "@snowscape/snow-lib";
import StandardButton from "../../../components/Buttons/StandardButton";

const sectionStyle =
  "md:bg-full-white md:p-3 md:rounded-md mb-12 md:border border-[#cccccc]";
const CompaniesPage = ({ currentUser }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const companyQuery = useQuery({
    queryKey: [`user/${currentUser.uid}/companies`],
  });
  const accountQuery = useQuery({
    queryKey: [`user/${currentUser.uid}/account`],
  });
  const queryClient = useQueryClient();
  return (
    <>
      <HeaderBar
        title="Companies / Subcontractors"
        buttons={[
          {
            color: "blue",
            enabled: true,
            label: "Create Company",
            onClick: () => navigate("/admin/user/company/create"),
          },
        ]}
      />
      <ContentWrapper loading={companyQuery.isLoading}>
        {companyQuery.isLoading ? null : (
          <>
            <section className={sectionStyle}>
              <h2 className="font-bold text-lg">Current Company</h2>
              <p className="text-description-gray mb-2">
                Set a company to view it's data on other pages (i.e. Routes,
                Properties, etc).
              </p>
              <SwitchCompanyDropdown />
            </section>

            {auth.canAdminCompany(currentUser, currentUser.currentCompanyId) ? (
              <section
                className={`${sectionStyle} flex flex-col md:flex-row justify-between`}
              >
                <div>
                  <h2 className="font-bold text-lg">
                    Company Specific Settings
                  </h2>
                  <p className="text-description-gray mb-2">
                    Here you can adjust settings specific to this company like
                    email reports and temperature units.
                  </p>
                </div>
                <div>
                  <StandardButton
                    label="Company&nbsp;Settings"
                    onClick={() =>
                      navigate(
                        `/admin/user/company/${currentUser.currentCompanyId}`,
                      )
                    }
                  />
                </div>
              </section>
            ) : null}

            <section className={sectionStyle}>
              <h2 className="font-bold text-lg">
                All Companies
                <Usage
                  name="Company Licenses"
                  small={true}
                  loading={accountQuery.isLoading}
                  using={accountQuery.data?.usage.activeCompanies}
                  total={accountQuery.data?.authorizations.authorizedCompanies}
                  className="ml-2"
                />
              </h2>
              <p className="text-description-gray ">
                All the companies you own or participate in. You can add any
                companies you sub-contract with as a separate company on this
                list. Click on a company for details or to{" "}
                <Link
                  className="underline"
                  to="/docs/guides/deactivate-company"
                >
                  deactivate it for a season
                </Link>
                .
              </p>

              <CompaniesTable
                data={companyQuery.data}
                currentUser={currentUser}
              />
            </section>
          </>
        )}
      </ContentWrapper>
    </>
  );
};

function CompanyTableItem({ currentUser, company }) {
  let canAdmin = auth.canAdminCompany(currentUser, company._id);
  const Component = canAdmin ? Link : "div";
  return (
    <li className="text-description-gray hover:bg-[#f1f1f1]">
      <Component
        to={`/admin/user/company/${company._id}`}
        className={`w-full flex flex-row justify-between  py-4 md:px-2 ${canAdmin ? "" : "opacity-30"}`}
        data-test-company={company._id}
      >
        <div className="left">
          <div className="font-semibold text-full-black">
            {company.companyName}
          </div>
          <span className="mr-2">
            {company.users.length} user{company.users.length > 1 ? "s" : ""}
          </span>
          {company.isPaid ? null : (
            <span className="text-snow-red">Inactive</span>
          )}
        </div>
        <div
          className="right capitalize"
          data-test-role={company.isOwner ? "owner" : company.role}
        >
          {company.isOwner ? "owner" : company.role}
        </div>
      </Component>
    </li>
  );
}
function CompaniesTable({ data, currentUser }) {
  return (
    <ol className="text-left w-full mt-4">
      {data.userCompanies.map((c) => (
        <CompanyTableItem key={c._id} company={c} currentUser={currentUser} />
      ))}
    </ol>
  );
}

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(CompaniesPage);
export { CompaniesPage };
