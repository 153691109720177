import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function SubtleButton({
  onClick,
  disabled,
  loading,
  loaderColor,
  loaderSize,
  label,
  children,
  className,
  testId,
  type = "button",
  buttonRole = "default",
}) {
  let roleCSS = "bg-full-white text-full-black border-[#cccccc]";
  if (buttonRole === "primary") {
    roleCSS = "bg-snow-blue-3 text-full-white border-snow-blue-3";
  }
  return (
    <button
      onClick={(e) => {
        if (!loading && typeof onClick === "function") onClick(e);
      }}
      disabled={disabled || loading}
      className={`border  rounded-md p-2 text-sm shadow-md font-semibold ${roleCSS} ${disabled ? "cursor-not-allowed opacity-40" : ""} ${className ?? ""}`}
      data-testid={testId ?? null}
      type={type}
    >
      {loading ? (
        <PulseLoader
          color={loaderColor || "#fffafa"}
          size={loaderSize || 5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        label ?? children
      )}
    </button>
  );
}

export default SubtleButton;
